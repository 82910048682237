import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const DocCheckIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="m 16.595811,9.7331322 c -0.2929,-0.29289 -0.7677,-0.29289 -1.0606,0 l -3.886112,3.4715318 -1.6693835,-1.536499 -0.08413,-0.0726 c -0.2936601,-0.2178 -0.7103101,-0.1935 -0.9765301,0.0728 -0.29283,0.2929 -0.29273,0.7678 2.3e-4,1.0606 l 2.2005136,2.067299 0.0841,0.0726 c 0.2936,0.2178 0.7102,0.1936 0.9764,-0.0727 l 4.415512,-4.002402 0.0726,-0.0841 c 0.2179,-0.2936 0.1937,-0.7102598 -0.0726,-0.9765298 z"
                fill={fill || "currentColor"}
            />
            <path
                {..._fillCommonProps}
                d="m 8.63769,22 c -0.412,0 -0.803,-0.032 -1.171,-0.095 l -0.218,-0.041 c -2.371,-0.493 -3.749,-2.309 -3.749,-5.073 V 7.209 c 0,-0.334 0.02,-0.654 0.06,-0.96 C 3.90869,3.564 5.75369,2 8.63769,2 h 7.72501 c 3.196,0 5.107,1.92 5.137,5.132 v 0.077 9.582 c 0,3.257 -1.916,5.209 -5.137,5.209 z m 0,-18.543 c -2.456,0 -3.701,1.263 -3.701,3.752 v 9.582 c 0,2.489 1.245,3.752 3.701,3.752 h 7.71601 c 2.455,0 3.692,-1.263 3.692,-3.752 V 7.209 c 0,-2.489 -1.237,-3.752 -3.692,-3.752 z m 8.16801,12.777 c 0,-0.4 -0.319,-0.724 -0.713,-0.724 H 8.88169 c -0.39399,0 -0.71399,0.324 -0.71399,0.724 0,0.4 0.32,0.724 0.71399,0.724 h 7.21101 c 0.394,0 0.713,-0.324 0.713,-0.724 z M 16.704785,7.7699976 c 0,-0.374 -0.277,-0.688 -0.643,-0.729 L 8.8907,7.042 c -0.36701,0.041 -0.644,0.355 -0.644,0.729 0,0.373 0.27699,0.687 0.644,0.728 l 7.171085,-0.001 c 0.366,-0.041 0.643,-0.355 0.643,-0.7279996 z M 12.56404,10.132195 c 0,-0.3739997 -0.277,-0.6879997 -0.643,-0.7289998 l -3.171085,10e-4 c -0.36701,0.041 -0.644,0.3550001 -0.644,0.7289998 0,0.373 0.27699,0.687 0.644,0.728 l 3.171085,-0.001 c 0.366,-0.041 0.643,-0.355 0.643,-0.728 z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
