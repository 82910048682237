import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const ScheduleIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path
                {..._fillCommonProps}
                fill={fill || "currentColor"}
                d="M16.335 3.50002H7.66598C4.2684 3.50002 2.00098 5.75019 2.00098 8.97237V16.5278C2.00098 19.7534 4.26167 22.0002 7.66598 22.0002H16.334C19.7386 22.0002 22.001 19.753 22.001 16.5278V8.97237C22.001 5.74733 19.7388 3.50002 16.335 3.50002ZM7.66598 4.88753H16.335C18.8855 4.88753 20.501 6.49239 20.501 8.97237V16.5278C20.501 19.0079 18.8854 20.6127 16.334 20.6127H7.66598C5.11512 20.6127 3.50098 19.0085 3.50098 16.5278V8.97237C3.50098 6.49554 5.12132 4.88753 7.66598 4.88753ZM9.91309 16.7866C9.91309 17.2888 10.3217 17.6974 10.8247 17.6974C11.3276 17.6974 11.7362 17.2888 11.7362 16.7866C11.7362 16.2844 11.3276 15.875 10.8247 15.875C10.3217 15.875 9.91309 16.2844 9.91309 16.7866ZM14.2324 17.6974C13.7294 17.6974 13.3208 17.2888 13.3208 16.7866C13.3208 16.2844 13.7294 15.875 14.2324 15.875C14.7353 15.875 15.1439 16.2844 15.1439 16.7866C15.1439 17.2888 14.7353 17.6974 14.2324 17.6974ZM16.729 16.7866C16.729 17.2888 17.1376 17.6974 17.6406 17.6974C18.1435 17.6974 18.5521 17.2888 18.5521 16.7866C18.5521 16.2844 18.1435 15.875 17.6406 15.875C17.1376 15.875 16.729 16.2844 16.729 16.7866Z"
            />
            <path
                fill={fill || "currentColor"}
                d="M19.8258 9.62238C21.0266 9.62238 22 9.95514 22 10.3656C22 10.7419 21.182 11.0529 20.1208 11.1021L19.8258 11.1089H4.17424C2.97344 11.1089 2 10.7761 2 10.3656C2 9.98935 2.81796 9.67838 3.87921 9.62916L4.17424 9.62238H19.8258Z"
            />
            <path
                fill={fill || "currentColor"}
                d="M19.8258 10.3656C21.0266 10.3656 22 10.6984 22 11.1089C22 11.4852 21.182 11.7961 20.1208 11.8454L19.8258 11.8521H4.17424C2.97344 11.8521 2 11.5194 2 11.1089C2 10.7326 2.81796 10.4216 3.87921 10.3724L4.17424 10.3656H19.8258Z"
            />
        </svg>
    );
};
