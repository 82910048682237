import React from "react";
import { IAppIco } from "../types";

export const SearchIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1115 2C6.80323 2 2.5 6.20819 2.5 11.3993C2.5 16.5903 6.80323 20.7985 12.1115 20.7985C14.3819 20.7985 16.4684 20.0287 18.113 18.7415L21.2371 21.7886L21.3202 21.8586C21.6102 22.0685 22.0214 22.0446 22.2839 21.7873C22.5726 21.5043 22.572 21.0459 22.2825 20.7636L19.1952 17.7523C20.7649 16.0794 21.7231 13.8487 21.7231 11.3993C21.7231 6.20819 17.4198 2 12.1115 2ZM12.1115 3.44774C16.6022 3.44774 20.2426 7.00776 20.2426 11.3993C20.2426 15.7908 16.6022 19.3508 12.1115 19.3508C7.62086 19.3508 3.98044 15.7908 3.98044 11.3993C3.98044 7.00776 7.62086 3.44774 12.1115 3.44774Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
