import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const PaperMinusIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2365 2.7619H8.5845C6.5245 2.7619 4.7505 4.4309 4.7505 6.4909V17.3399C4.7505 19.5159 6.4085 21.1149 8.5845 21.1149H16.5725C18.6325 21.1149 20.3025 19.3999 20.3025 17.3399V8.0379L15.2365 2.7619Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M14.9738 2.75021V5.65921C14.9738 7.07921 16.1228 8.23121 17.5428 8.23421C18.8588 8.23721 20.2058 8.23821 20.2968 8.23221"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M14.7941 13.7472H9.8931" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
