import { Employee, NavigationRoute } from "../types";

export enum ActionType {
    SetFetchingData = "SET_FETCHING_DATA",
    SetShowFullMenu = "SET_SHOW_FULL_MENU",
    SetCurrentPage = "SET_CURRENT_PAGE",
    SetUserNavLinks = "SET_NAV_LINKS",
    SetUserInfo = "SET_USER_INFO",
    SetUserLinks = "SET_USER_LINKS",
    SetConfigLink = "SET_CONFIG_LINK",
    SetActivePage = "SET_ACTIVE_PAGE",
}

interface ISetFetchingData {
    type: ActionType.SetFetchingData;
    payload: boolean;
}

interface ISetShowFullMenu {
    type: ActionType.SetShowFullMenu;
    payload: boolean;
}

interface ISetCurrentPage {
    type: ActionType.SetCurrentPage;
    payload: any;
}

interface ISetUserNavLinks {
    type: ActionType.SetUserNavLinks;
    payload: NavigationRoute[];
}

interface ISetUserInfo {
    type: ActionType.SetUserInfo;
    payload: Employee;
}

interface IFormLinks {
    type: ActionType.SetUserLinks;
    payload: NavigationRoute[];
}

interface ISetConfigLink {
    type: ActionType.SetConfigLink;
    payload: string;
}

interface ISetActivePage {
    type: ActionType.SetActivePage;
    payload: string;
}

export type Actions =
    | ISetFetchingData
    | ISetShowFullMenu
    | ISetCurrentPage
    | ISetUserNavLinks
    | ISetUserInfo
    | IFormLinks
    | ISetConfigLink
    | ISetActivePage;

export const setFetchingData = (response: boolean): ISetFetchingData => ({
    type: ActionType.SetFetchingData,
    payload: response,
});

export const setShowFullMenu = (response: boolean): ISetShowFullMenu => ({
    type: ActionType.SetShowFullMenu,
    payload: response,
});

export const setCurrentPage = (response: any): ISetCurrentPage => ({
    type: ActionType.SetCurrentPage,
    payload: response,
});

export const setUserNavLinks = (response: NavigationRoute[]): ISetUserNavLinks => {
    return {
        type: ActionType.SetUserNavLinks,
        payload: response,
    }
};

export const setUserInfo = (response: Employee): ISetUserInfo => ({
    type: ActionType.SetUserInfo,
    payload: response,
});

export const setFormLinks = (response: NavigationRoute[]): IFormLinks => ({
    type: ActionType.SetUserLinks,
    payload: response,
});

export const setActivePage = (response: string): ISetActivePage => ({
    type: ActionType.SetActivePage,
    payload: response,
});
