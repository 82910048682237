import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const UserCheckIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <circle
                cx="9.4601269"
                cy="6.9859171"
                r="4.7780299"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                {..._fillCommonProps}
                d="m 1.8811463,18.409287 c -0.00129,-0.3359 0.07383,-0.6677 0.21968,-0.9703 0.45766,-0.9153 1.74828,-1.4004 2.81922,-1.6201 0.77236,-0.1648 1.55539,-0.275 2.34325,-0.3295 1.45863,-0.1282 2.9257307,-0.1282 4.3844307,0 0.7878,0.0552 1.5708,0.1653 2.3433,0.3295 1.0709,0.2197 2.3615,0.659 2.8192,1.6201 0.2933,0.6168 0.2933,1.3329 0,1.9497 -0.4577,0.9611 -1.7483,1.4004 -2.8192,1.611 -0.7715,0.1716 -1.5548,0.2848 -2.3433,0.3386 -1.1872,0.1007 -2.3800007,0.119 -3.5698007,0.055 -0.2746,0 -0.54,0 -0.81463,-0.055 -0.78554,-0.0531 -1.56585,-0.1663 -2.3341,-0.3386 -1.08009,-0.2106 -2.36155,-0.6499 -2.82837,-1.611 -0.1451,-0.3061 -0.22015,-0.6407 -0.21968,-0.9794 z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <g fill="none" transform="matrix(1.0416667,0,0,1.0416667,-30.727104,-12.398502)">
                <g transform="matrix(1.0531843,0,0,1.0849767,27.757407,9.0039495)">
                    <g fill="none" transform="translate(14.466894,8.2459361)">
                        <path
                            d="m 1.9786487,5.496304 2.2068528,2.3435989 4.1967,-4.0078125"
                            stroke={stroke || "currentColor"}
                            {..._strokeCommonProps}
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};
