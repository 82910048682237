import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const TicketStarIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg
            className={_classList}
            width={width || "25"}
            height={height || "24"}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                {..._fillCommonProps}
                d="M18.3573 20.4168C20.2323 20.4168 21.7503 18.8978 21.7503 17.0238V14.3238C20.5103 14.3238 19.5103 13.3238 19.5103 12.0848C19.5103 10.8448 20.5103 9.84579 21.7503 9.84579L21.7483 7.14279C21.7483 5.26879 20.2303 3.74979 18.3563 3.74979H6.6443C4.7703 3.74979 3.2513 5.26879 3.2513 7.14279L3.2503 9.93279C4.4893 9.93279 5.4893 10.8448 5.4893 12.0848C5.4893 13.3238 4.4893 14.3238 3.2503 14.3238V17.0238C3.2503 18.8978 4.7683 20.4168 6.6423 20.4168H18.3573Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                {..._fillCommonProps}
                d="M12.4998 13.8543L14.2398 14.7693C14.4028 14.8543 14.5938 14.7163 14.5628 14.5353L14.2298 12.5963L15.6388 11.2253C15.7708 11.0963 15.6978 10.8733 15.5158 10.8463L13.5698 10.5633L12.6988 8.79929C12.6178 8.63429 12.3828 8.63429 12.3008 8.79929L11.4298 10.5633L9.4848 10.8463C9.3028 10.8733 9.2298 11.0963 9.3618 11.2253L10.7698 12.5963L10.4368 14.5353C10.4058 14.7163 10.5968 14.8543 10.7598 14.7693L12.4998 13.8543Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
