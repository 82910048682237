import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const PaperFailIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2366 2.7619H8.4786C6.4196 2.7619 4.7496 4.4309 4.7496 6.4909V17.3399C4.7496 19.5159 6.5136 21.1149 8.6896 21.1149H16.5726C18.6326 21.1149 20.3016 19.3999 20.3016 17.3399V8.0379L15.2366 2.7619Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M14.9739 2.75021V5.65921C14.9739 7.07921 16.1229 8.23121 17.5419 8.23421C18.8589 8.23721 20.2059 8.23821 20.2969 8.23221"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M14.0764 14.6476L10.6104 11.1816" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M10.6106 14.6476L14.0766 11.1816" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
