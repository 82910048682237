import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const HeartIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M6.98311 2.81529C3.31152 3.99738 1.67386 8.00569 2.90482 11.8295C3.52947 13.6301 4.54534 15.2485 5.88004 16.5762C7.71101 18.3545 9.72254 19.9194 11.8849 21.2491L12.1304 21.3961C12.3656 21.5369 12.6598 21.5344 12.8926 21.3897L13.1218 21.2473C15.2812 19.9194 17.2927 18.3545 19.1174 16.5824C20.4584 15.2485 21.4743 13.6301 22.0937 11.8452C23.3291 8.00801 21.6847 3.9978 18.012 2.81535L17.7463 2.73623C16.0624 2.27309 14.273 2.5013 12.7645 3.35535L12.4964 3.51429L12.233 3.35623C10.6419 2.45344 8.7404 2.25003 6.98311 2.81529ZM11.8746 4.85427L12.0714 4.99538C12.3307 5.18111 12.6806 5.17742 12.936 4.98625C14.266 3.99057 15.9873 3.70082 17.5641 4.20669C20.4097 5.12284 21.7047 8.28096 20.7064 11.382C20.165 12.9417 19.2687 14.3696 18.0916 15.5405L17.5636 16.0421C16.141 17.3642 14.6026 18.561 12.9691 19.6156L12.5013 19.9098L12.644 19.9998C10.5805 18.7308 8.65375 17.2319 6.90582 15.5343C5.73505 14.3696 4.83877 12.9417 4.29207 11.3664C3.29808 8.27801 4.58746 5.12212 7.43153 4.20646C8.93331 3.72339 10.5706 3.96289 11.8746 4.85427ZM16.3703 6.48374C15.9855 6.36093 15.5739 6.57304 15.451 6.95749C15.3281 7.34194 15.5404 7.75316 15.9252 7.87597C16.6814 8.11735 17.2206 8.79803 17.2881 9.60096C17.322 10.0031 17.6757 10.3017 18.0782 10.2679C18.4807 10.2341 18.7796 9.8807 18.7457 9.47853C18.6288 8.08859 17.6917 6.90551 16.3703 6.48374Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
