import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const WalletIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path
                d="M21.6389 14.3958H17.5906C16.1042 14.3949 14.8993 13.1909 14.8984 11.7045C14.8984 10.218 16.1042 9.01413 17.5906 9.01322H21.6389"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M18.049 11.6429H17.7373" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                {..._fillCommonProps}
                d="M7.74766 3H16.3911C19.2892 3 21.6388 5.34951 21.6388 8.24766V15.4247C21.6388 18.3229 19.2892 20.6724 16.3911 20.6724H7.74766C4.84951 20.6724 2.5 18.3229 2.5 15.4247V8.24766C2.5 5.34951 4.84951 3 7.74766 3Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M7.03516 7.53816H12.4341" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
