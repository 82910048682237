import React, { useEffect, useState } from 'react';
import Remote from '../../api';
import {Row, Col, Input, Button, Select, Tooltip} from 'antd';
import './Cards.less'
import {CardFilter} from "./CardFilter";
import Fuse from 'fuse.js'
import { AppLoader } from "../DashboardWrapper/AppLoader";
import { AppProvider } from '../../context';
import App from "../App";
import {CardAction} from "./CardType";

const { Option } = Select;

function Cards() {
    const [originalCardList, setOriginalCardList] = useState([] as any[]);
    const [cardList, setCardList] = useState([] as any[]);

    const [displayCardList, setDisplayCardList] = useState([]);
    const [showMe, setShowMe] = useState(false);
    const [displayShowMore, setDisplayShowMore] = useState(false);
    const [nextUrl, setNextUrl] = useState("");
    const [selectedFilter, setSelectedFilter] = useState({} as any);
    const [userProfile, setUserProfile] = useState({} as any);
    const [fues, setFuse] = useState({} as any);

    useEffect(() => {
        Remote.getProfile()
            .then(res => {
                setUserProfile(res)
            });
    }, [])

    function onSearchClick() {
        setDisplayShowMore(false);
        
        if (!fues.search) {
            getCards(selectedFilter);
        } else {
            let searchResult: any[] = originalCardList.slice()
            if (selectedFilter.search) {
                const searchResultFues = fues.search(selectedFilter.search);
                searchResult = searchResultFues.map((result: any) => {
                    return result.item
                })
                
            }
            if (selectedFilter.payPoint) {
                searchResult = searchResult.filter(card => {
                    return card.payPoint === selectedFilter.payPoint 
                });
            }

            if (selectedFilter.periodEndDate) {
                searchResult = searchResult.filter(card => {
                    return card.periodEndDate === selectedFilter.periodEndDate 
                });
            }

            if (selectedFilter.employee) {
                searchResult = searchResult.filter(card => {
                    return card.employee.toLowerCase() === selectedFilter.employee.toLowerCase()
                });
            }

            if (selectedFilter.status) {
                searchResult = searchResult.filter(card => {
                  return card.messageStatus.toLowerCase() === selectedFilter.status.toLowerCase()
                });
            }

            if (selectedFilter.subject) {
                searchResult = searchResult.filter(card => {
                    return card.subject.toLowerCase() === selectedFilter.subject.toLowerCase()  
                });
            }

            if (selectedFilter.messageType) {
                searchResult = originalCardList.filter(card => {
                    return card.messageType.toLowerCase() === selectedFilter.messageType.toLowerCase() 
                });
            }

            setCardList(searchResult)            
        }
        
    }

    async function getCards(filter?: any) {
        let cardsData: any = [];
           
        cardsData = await Remote.getCardList();
       

        if (!cardsData || cardsData.length === 0) {
            return;
        }

        const processedData = cardsData.map((d: any) => {
            d.showMore = false;
            return d;
        })
        setOriginalCardList(processedData)
        setCardList(processedData);
        setFuse(new Fuse(processedData, {
            keys: ['alertMessage', 'messageDetails'],
            includeScore: true,
            threshold: 0.4
            
        }))

    }

    function handleShowMore(card: any) {
        const newCardList = cardList.map(originalCard => {
            if (originalCard.id === card.id) {
                originalCard.showMore = !originalCard.showMore;
            }
            return originalCard
        })
        setCardList(newCardList)
    }

    async function handleShowMoreRows() {
        const cardsData = await Remote.getNextCardList(nextUrl)

        if (cardsData.value?.length > 0) {
            const processedData = cardsData.value.map((d: any) => {
                d.showMore = false;
                return d;
            })
            setOriginalCardList(processedData)
            setCardList([...cardList, ...processedData]);

            if (cardsData["@nextLink"]) {
                setDisplayShowMore(true)
                setNextUrl(cardsData["@nextLink"])
            } else {
                setDisplayShowMore(false)
            }
        } else {
            setDisplayShowMore(false)
        }
    }

    function renderActionButtons(id: string, actions: CardAction) {
        return Object.entries(actions).map(([actionKey, value], index) => {
            return (
                <Button size="small" key={index} onClick={() => performAction(id, actionKey, value)}>
                    {value}
                </Button>
            )
            
        });
      }
      
      function performAction(id: string, key: string, value: string) {
        console.log(`key: ${key}, value: ${value}`)
        Remote.postCardAction(id, key)
        // todo: add post call to perform action
      }

    return (
        <>
            <AppProvider>
                <React.Suspense
                    fallback={
                        <AppLoader isAppWrap={true} tip="Loading..." />
                    }
                >   
                    <div id="SS-DashboardWrapper">
                        <App />
                    </div>            
                </React.Suspense>
            </AppProvider>
            
            <div className='ss-payroll-dashboard-content flex-grow-1'>
                <h1>Payroll Management Dashboard</h1>

                <div className="ss-row ss-row-space-between mt-2">
                    <div className="inline-block">
                     
                    </div>
                    <div className="inline-block">
                        <span>Co Id: {userProfile.CompanyNo}</span>
                        {/* <span className="ml-2">DC Date: 23/08/2021</span>
                        <span className="ml-2">Process In: 22.15 Hrs</span> */}
                    </div>
                </div>
                <div className="ss-payroll-d-table">
                    <CardFilter selectedFilter={selectedFilter} onSearchClick={onSearchClick}></CardFilter>
                    <Row className="card-table-heading py-1 px-2 mt-2 grid grid-cols-5 gap-4 bg-blue-300">
                        <Col span={2}>Status</Col>
                        <Col span={5}>Message</Col>
                        <Col span={8}>Details</Col>
                        <Col span={4}>Variation</Col>
                        <Col span={3}>Employee</Col>
                    </Row>

                    <div>
                        {cardList.map(card =>
                            <div key={card.id}>
                                <Row className="card-table-row">
                                    <Col span={2} className="text-ellipsis text-cap">{card.messageStatus}</Col>
                                    <Col span={5}>
                                    <Tooltip title={card.alertMessage}>
                                            <span className="text-ellipsis">{card.alertMessage}</span>
                                        </Tooltip>
                                        </Col>
                                    <Col span={8}>
                                        <Tooltip title={card.messageDetails}>
                                            <span className="text-ellipsis">{card.messageDetails}</span>
                                        </Tooltip>
                                    </Col>
                                    <Col span={4} className="text-ellipsis">{card.entity?.Variation}</Col>
                                    <Col span={3} className="text-ellipsis">{card.entity?.EmpCommonName} - {card.employee}</Col>
                                    {renderActionButtons(card.id, card.actions)}
                                    {/* <Col span={2} className="text-right">
                                        <Button size="small" onClick={() => handleShowMore(card)}>More</Button>
                                    </Col> */}
                                </Row>
                                {/* {card.showMore &&
                                <Row className="card-table-row">
                                    <Col span={4} ></Col>
                                    <Button size="small" className="ml-4 btn-flat">FORWARD</Button>
                                    <Button size="small" className="ml-4 btn-flat">TRANSACTION</Button>
                                    <Button size="small" className="ml-4 btn-flat">PAY REVIEW</Button>
                                    <Col span={5} className="ml-2">
                                        <Input className="input-flat" placeholder="Notes" />
                                    </Col>

                                </Row>} */}
                            </div>
                        )}
                    </div>
                    {displayShowMore &&
                    <Row className="mt-4" justify="center">
                            <Button onClick={() => handleShowMoreRows()}>Show More</Button>
                    </Row>
                    }
                </div>
            </div>
        </>
        
    );
}

export default Cards;

