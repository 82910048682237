import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const BookmarkIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M15.4857 2H9.51434C6.40915 2 4.5 3.49929 4.5 6.43434V20.3309C4.50448 20.613 4.57042 20.8782 4.69263 21.1174L4.78627 21.2719L4.88431 21.3975C5.39545 21.9969 6.2112 22.1624 6.88442 21.8037L12.474 18.6635L18.0863 21.7971C18.3368 21.9291 18.5965 21.9957 18.8608 22C19.3038 22.0001 19.7179 21.8242 20.0232 21.5112C20.3285 21.1982 20.5 20.7736 20.5 20.3309V6.25765C20.5 3.43503 18.5482 2 15.4857 2ZM9.51434 3.44775H15.4857C17.85 3.44775 19.088 4.35788 19.088 6.25765V20.3309C19.088 20.3897 19.0652 20.446 19.0247 20.4875C18.9842 20.529 18.9293 20.5523 18.872 20.5523C18.8355 20.5517 18.7887 20.5397 18.7463 20.5173L13.1435 17.3894C12.725 17.1576 12.2233 17.1576 11.8068 17.3883L6.21962 20.5265C6.13485 20.5715 6.01373 20.5421 5.94919 20.4519L5.91172 20.3897C5.91894 20.4 5.91764 20.3876 5.9154 20.3662C5.914 20.3528 5.91223 20.3359 5.91195 20.3189L5.91205 6.43434C5.91205 4.39579 7.11925 3.44775 9.51434 3.44775ZM16.6139 9.0405C16.6139 8.64072 16.2978 8.31663 15.9079 8.31663H9.04042L8.94462 8.32324C8.60001 8.37117 8.3344 8.67404 8.3344 9.0405C8.3344 9.44029 8.6505 9.76438 9.04042 9.76438H15.9079L16.0037 9.75777C16.3483 9.70984 16.6139 9.40697 16.6139 9.0405Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
