import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const UpSquareIco: React.FC<IAppIco> = ({ asset, height, width, stroke, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M16.334 2.75H7.665C4.645 2.75 2.75 4.889 2.75 7.916V16.084C2.75 19.111 4.635 21.25 7.665 21.25H16.334C19.364 21.25 21.25 19.111 21.25 16.084V7.916C21.25 4.889 19.364 2.75 16.334 2.75Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M12 7.914L12 16.086" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                d="M8.25205 11.6777L12 7.9137L15.748 11.6777"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
