import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const ClockSquareIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M16.8347 2.75021H8.1657C5.1447 2.75021 3.2507 4.88921 3.2507 7.91621V16.0842C3.2507 19.1112 5.1347 21.2502 8.1657 21.2502H16.8337C19.8647 21.2502 21.7507 19.1112 21.7507 16.0842V7.91621C21.7507 4.88921 19.8647 2.75021 16.8347 2.75021Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M15.8913 14.0177L12.5003 11.9947V7.63373"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
