import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const InfoSquareIco: React.FC<IAppIco> = ({ asset, height, stroke, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path d="M11.9902 15.7961V11.3771" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M11.9891 8.2043H11.9991" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                {..._fillCommonProps}
                d="M16.334 2.7502H7.665C4.644 2.7502 2.75 4.8892 2.75 7.9162V16.0842C2.75 19.1112 4.635 21.2502 7.665 21.2502H16.333C19.364 21.2502 21.25 19.1112 21.25 16.0842V7.9162C21.25 4.8892 19.364 2.7502 16.334 2.7502Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
