import React from "react";
import { Button } from "antd";
import { UserTopNav } from ".";
import { AppContext } from "../../context";
import { AppIcon } from "./SVGFiles";

interface Props {
    title: string;
    onMenuClick: () => void;
}

export const AppHeader: React.FC<Props> = ({ title, onMenuClick }) => {
    const { state } = React.useContext(AppContext);
    return (
        <header className="ss-dashboard-wrap-main-header">
            <div>
                <h1>
                    <Button className="menu-burger" type="link" shape="circle" icon={<AppIcon asset="burger-menu" />} onClick={() => onMenuClick()} />
                    <span>
                        {state.user?.CompanyNo && state.user?.EmployeeNo && (
                            <img
                                src={`${process.env.REACT_APP_DASHBOARD_URL as string}/Root/Logo?company=${state.user?.CompanyNo}&employee=${
                                    state.user?.EmployeeNo
                                }`}
                                alt="Affinity - making work life easy"
                                height={48}
                            />
                        )}
                        {window.location.href.includes("testaffinitylogon") && <span>TE</span>}
                    </span>
                </h1>
                <div>
                    <UserTopNav />
                </div>
            </div>
        </header>
    );
};
