import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const FileDownloadIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path
                {..._fillCommonProps}
                fill={fill || "currentColor"}
                d="M14.3277 2.0119C14.2855 2.00421 14.242 2.0002 14.1976 2.0002C14.1532 2.0002 14.1097 2.00421 14.0674 2.0119H7.93016C5.49031 2.0119 3.43359 4.02973 3.43359 6.4909V17.2279C3.43359 19.8254 5.39627 21.8649 7.93016 21.8649H15.7658C18.1746 21.8649 20.1604 19.7229 20.1604 17.2279V8.0379C20.1604 7.84426 20.0869 7.65812 19.9553 7.51845L14.986 2.24245C14.8472 2.09516 14.6556 2.0119 14.4553 2.0119H14.3277ZM13.4619 3.5111L7.93016 3.5119C6.29058 3.5119 4.90498 4.8713 4.90498 6.4909V17.2279C4.90498 19.0034 6.21526 20.3649 7.93016 20.3649H15.7658C17.3428 20.3649 18.689 18.9128 18.689 17.2279L18.6886 8.98328L17.8289 8.98627C17.5017 8.98587 17.1297 8.98516 16.716 8.9842C14.9795 8.98046 13.5627 7.59367 13.467 5.84761L13.4619 5.6592V3.5111ZM17.8833 7.48404L16.7193 7.4842C15.7321 7.48208 14.9333 6.66577 14.9333 5.6592V4.35215L17.8833 7.48404ZM11.4186 8.6908C11.7911 8.6908 12.0989 8.97295 12.1476 9.33903L12.1543 9.4408V14.1966L13.6818 12.634C13.9685 12.3405 14.4343 12.3395 14.7222 12.6318C14.9839 12.8975 15.0085 13.3141 14.7955 13.6081L14.7244 13.6924L11.9399 16.543L11.9395 16.5434C11.6781 16.8111 11.2679 16.8354 10.9794 16.6163L10.9728 16.6104C10.9483 16.5914 10.925 16.5708 10.903 16.5488L10.8968 16.5433L8.11297 13.6923C7.82632 13.3988 7.82738 12.9239 8.11534 12.6317C8.37713 12.366 8.7859 12.3427 9.07342 12.5613L9.15577 12.6341L10.6829 14.1989V9.4408C10.6829 9.02659 11.0123 8.6908 11.4186 8.6908Z"
            />
        </svg>
    );
};
