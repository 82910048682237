import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const ScanIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M9.64486 3.71496C9.64461 3.31985 9.31799 2.99975 8.91534 3L7.28185 3.001L7.08449 3.00596C4.99232 3.10911 3.32902 4.80561 3.32902 6.88317V8.74985L3.33568 8.84692C3.38395 9.19611 3.68899 9.46525 4.05809 9.46525C4.46075 9.46525 4.78716 9.14495 4.78716 8.74985V6.88317L4.79207 6.72825C4.87347 5.44759 5.95718 4.43329 7.28313 4.43181L8.91625 4.43081L9.01517 4.42422C9.371 4.37663 9.64509 4.07714 9.64486 3.71496ZM17.7097 3C19.8243 3 21.5628 4.643 21.6662 6.69375L21.6711 6.88716V8.74985C21.6711 9.14495 21.3447 9.46525 20.942 9.46525C20.5729 9.46525 20.2679 9.19611 20.2196 8.84692L20.2129 8.74985V6.88716C20.2129 5.58941 19.1702 4.5163 17.8672 4.43567L17.7097 4.43081H16.1118C15.7092 4.43081 15.3827 4.11051 15.3827 3.7154C15.3827 3.35322 15.657 3.0539 16.0129 3.00653L16.1118 3H17.7097ZM22.7709 12.0755C23.1736 12.0755 23.5 12.3958 23.5 12.7909C23.5 13.1531 23.2257 13.4524 22.8699 13.4998L22.7709 13.5063H21.6711V17.1128C21.6711 19.1872 19.9964 20.8937 17.9067 20.9952L17.7097 21H16.1118C15.7092 21 15.3827 20.6797 15.3827 20.2846C15.3827 19.9224 15.657 19.6231 16.0129 19.5757L16.1118 19.5692H17.7097C19.0318 19.5692 20.1258 18.5454 20.208 17.2674L20.2129 17.1128V13.5063H4.78716V17.1168C4.78716 18.4176 5.81987 19.4819 7.12489 19.5632L7.28276 19.5682L8.91625 19.5692C9.3189 19.5694 9.64511 19.8899 9.64486 20.285C9.64463 20.6472 9.37016 20.9464 9.01427 20.9935L8.91534 21L7.28147 20.999C5.16407 20.9966 3.43661 19.363 3.33386 17.3105L3.32902 17.1168V13.5063H2.22907C1.82642 13.5063 1.5 13.186 1.5 12.7909C1.5 12.4287 1.77428 12.1294 2.13014 12.082L2.22907 12.0755H4.05809H20.942H22.7709Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
