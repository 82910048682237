import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const PaperPlusIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2363 2.7619H8.5843C6.5253 2.7619 4.7503 4.4309 4.7503 6.4909V17.3399C4.7503 19.5159 6.4083 21.1149 8.5843 21.1149H16.5723C18.6323 21.1149 20.3023 19.3999 20.3023 17.3399V8.0379L15.2363 2.7619Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M14.9736 2.75021V5.65921C14.9736 7.07921 16.1226 8.23121 17.5426 8.23421C18.8586 8.23721 20.2056 8.23821 20.2966 8.23221"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M14.7944 12.9142H9.8934" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M12.3437 15.3654V10.4644" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
