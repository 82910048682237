import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const StarIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2141 3.44083L15.5301 8.09983C15.6461 8.33483 15.8701 8.49883 16.1301 8.53683L21.3151 9.28583C21.5251 9.31383 21.7141 9.42383 21.8431 9.59183C22.0851 9.90683 22.0481 10.3528 21.7581 10.6238L18.0001 14.2578C17.8091 14.4378 17.7241 14.7018 17.7741 14.9588L18.6741 20.0868C18.7371 20.5118 18.4471 20.9098 18.0221 20.9788C17.8461 21.0058 17.6661 20.9778 17.5061 20.8988L12.8881 18.4778C12.6561 18.3518 12.3781 18.3518 12.1461 18.4778L7.49413 20.9118C7.10513 21.1098 6.62913 20.9628 6.41713 20.5818C6.33613 20.4278 6.30813 20.2528 6.33613 20.0818L7.23613 14.9538C7.28113 14.6978 7.19613 14.4348 7.01013 14.2538L3.23213 10.6208C2.92413 10.3148 2.92213 9.81683 3.22913 9.50883C3.23013 9.50783 3.23113 9.50583 3.23213 9.50483C3.35913 9.38983 3.51513 9.31283 3.68413 9.28283L8.87013 8.53383C9.12913 8.49283 9.35213 8.33083 9.47013 8.09583L11.7841 3.44083C11.8771 3.25183 12.0421 3.10683 12.2421 3.04083C12.4431 2.97383 12.6631 2.98983 12.8521 3.08483C13.0071 3.16183 13.1341 3.28683 13.2141 3.44083Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
