import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const GraphIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M13.923 2.00105C13.5474 1.98661 13.1739 2.12156 12.8925 2.37643C12.6684 2.57938 12.5162 2.84619 12.457 3.13824L12.4349 3.30295L12.4299 3.44914L12.8741 9.97348C12.9295 10.8051 13.6615 11.4382 14.5133 11.39L21.1588 10.9567C21.5477 10.9286 21.9041 10.7524 22.1545 10.4668C22.3538 10.2393 22.4737 9.95621 22.4977 9.65855L22.5 9.4567L22.4904 9.34084C21.8029 5.20162 18.2085 2.12227 13.923 2.00105ZM13.9113 3.44902L14.1287 3.45932C17.5119 3.66443 20.3318 6.0802 20.9854 9.33352L21.0182 9.51352L14.4214 9.94502C14.388 9.94688 14.3537 9.91719 14.3511 9.87814L13.9113 3.44902ZM9.2814 5.84532C10.002 5.74681 10.711 6.12453 10.9978 6.7606C11.0855 6.93485 11.1382 7.12392 11.1533 7.32102L11.5419 12.8095C11.5471 12.8847 11.5827 12.9548 11.6409 13.0042C11.6845 13.0413 11.7381 13.0645 11.798 13.0712L11.8599 13.0724L17.4341 12.7366C17.8867 12.71 18.3305 12.8675 18.6604 13.1719C18.9902 13.4763 19.1768 13.9004 19.1744 14.3912C18.9265 18.0037 16.273 21.0237 12.659 21.8065C9.04503 22.5893 5.33611 20.9474 3.55781 17.7848C3.08229 16.9695 2.76178 16.0778 2.6138 15.1749L2.56643 14.8359C2.5253 14.5821 2.50319 14.3257 2.5 14.0795L2.50311 13.8372C2.5134 10.0655 5.16156 6.80403 8.88809 5.92434L9.14383 5.86807L9.2814 5.84532ZM9.51643 7.27813L9.43217 7.28772L9.20369 7.33933C6.23526 8.05478 4.1062 10.6103 3.98796 13.621L3.9828 13.8661C3.97569 14.0525 3.9825 14.2392 4.00499 14.4378L4.03277 14.6408C4.13212 15.495 4.40795 16.3206 4.84914 17.0772C6.31632 19.6863 9.36669 21.0367 12.339 20.3929C15.3113 19.7491 17.4936 17.2653 17.6958 14.3414C17.6959 14.2974 17.6774 14.2552 17.6446 14.225C17.6228 14.2049 17.5959 14.1912 17.5675 14.1851L17.524 14.1817L11.959 14.5169C11.4894 14.5503 11.0255 14.3992 10.67 14.0971C10.3146 13.7951 10.0968 13.3669 10.0652 12.91L9.67702 7.42617C9.67632 7.41708 9.67385 7.40822 9.65682 7.37299C9.6315 7.31695 9.57648 7.28131 9.51643 7.27813Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
