import axios, { AxiosResponse } from "axios";
import { NavigationRoute, Employee } from "../types";
import { mockAuthGetInfo, mockConfigDashboard } from "../mockData/mockDashboardWrapper";

const { REACT_APP_LOGIN_URL } = process.env;
// const APP_CONTAINER = document.getElementById("SS-DashboardWrapper");
const APP_CONTAINER = false

declare var window: any;

// axios.defaults.baseURL = 'http://localhost:3000';
axios.interceptors.request.use((config) => {
    let authHeader: any = {
        // "Cache-Control": "no-cache",
    };

    config.withCredentials = true;
    config.headers = {
        ...config.headers,
        ...authHeader,
        "content-type": "application/json; charset=utf-8",
    };
    return config;
});

axios.interceptors.response.use(
    (res) => {
        switch (res.status) {
            case 401: // Unauthorize
                console.error('401')
                // window.location.href = `${REACT_APP_LOGIN_URL}?redirectUrl=${window.location.href}`;
                break;
        }
        // Return data API Content
        return res;
    },
    (errors) => {
        if (errors.response.status === 401) {
            console.error('401')
            // window.location.href = `${REACT_APP_LOGIN_URL}?redirectUrl=${window.location.href}`;
        }
        return Promise.reject(errors);
    }
);

interface IInitDataProps {
    user: Employee;
    navLinks?: NavigationRoute[];
    formLinks?: NavigationRoute;
    activePage?: string;
}

export function fetchInitialData() {
    let initData = getInitialData();
    return {
        payslip: wrapPromise(initData),
    };
}

export const getInitialData = async (): Promise<IInitDataProps> => {
    const navConfig = await getNavConfig();
    const { links, formLinks, activePage } = processUserNavLinks(navConfig);
    console.log('links', links)
    const user = await getUserInfo();

    user.EmployeeNo = user.EmployeeNumber || user.EmployeeNo;
    user.CompanyNo = user.CompanyNumber || user.CompanyNo;

    // Only SSL has the Firstname & Lastname field
    user.FirstName = user.Firstname || user.FirstName;
    user.LastName = user.Lastname || user.LastName;

    if (window.myVue !== undefined) {
        if (window.myVue.config?.globalProperties) {
            window.myVue.config.globalProperties.$config.value = navConfig.data;
            window.myVue.config.globalProperties.$user.value = user;
            if (!window.Affinity) {
                window.Affinity = {
                    user: user,
                    config: navConfig.data,
                    companyNumber: user.CompanyNo,
                    employeeNumber: user.EmployeeNo,
                    EmployeeNo: user.EmployeeNo,
                    CompanyNo: user.CompanyNo,
                };
            } else {
                window.Affinity.user = user;
                window.Affinity.config = navConfig.data;
                window.Affinity.companyNumber = user.CompanyNo;
                window.Affinity.employeeNumber = user.EmployeeNo;
                window.Affinity.user.CompanyNo = user.CompanyNo;
                window.Affinity.user.EmployeeNo = user.EmployeeNo;
            }
        } else {
            window.myVue.$config = navConfig.data;
            window.myVue.$user = user;
        }
    }

    return {
        user,
        navLinks: links,
        formLinks,
        activePage,
    };
};

export const getUserInfo = () => {
    // const userInfoApi = APP_CONTAINER?.dataset.user || "/Config/GetInfo";
    // const apiMethod = APP_CONTAINER?.dataset.userMethod || "post";
    // if (apiMethod === "get") {
        // return axios.get(`${userInfoApi}`).then((res) => res.data);
    // } else {
        // return axios.post(`${userInfoApi}`).then((res) => res.data);
    // }
    // return axios.get('https://dashboard.testaffinitylogon.com/Config/GetInfo').then((res) => res.data);
    

    return axios.get(`${process.env.REACT_APP_LEGWAPI_URL}/Auth/GetInfo`).then((res) => {
        const userRaw = res.data.data
        const user: Employee = {
            EmployeeNo: userRaw.employeeNumber,
            CompanyNo: userRaw.companyNo,
            FirstName: userRaw.firstName,
            LastName: userRaw.lastName,
        }

        return user
    });
    const mockResponse: AxiosResponse = {
        data: mockAuthGetInfo,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
      };
    return mockResponse.data
};

function processUserNavLinks(res: AxiosResponse<any, any>): {
    links: NavigationRoute[];
    formLinks: NavigationRoute;
    activePage: string;
} {
    const config: any = res.data;
    let activePage = "",
        hasActive = false;

    if (window.Affinity.app === "Dashboard") {
        activePage = "Dashboard";
    }

    const links: NavigationRoute[] = [
        {
            link: process.env.REACT_APP_DASHBOARD_URL as string,
            label: "Dashboard",
            icon: "dashboard",
            id: "Dashboard",
            enabled: true,
        },
    ];
    console.log('config', config)
    let formLinks: any = config.Forms.filter((m: any) => m.Url).map((m: any) => ({
        ...assignLinkValues(m),
    }));
    const _myProfileSubLinks: any = [];

    const myDetailsIndx = formLinks.findIndex((a: any) => a.id.includes("MyDetails") || a.id.includes("My Details")),
        myDetails = formLinks[myDetailsIndx];

    config.Apps.forEach((app: any) => {
        if (app.Link) {
            const { Menu, Name } = app.Link;
            let isCurrentPage = false,
                myDetailsIsInserted = false;
            let subLinks: NavigationRoute[] = [];
            const linkValues = assignLinkValues(app.Link);
            if (linkValues.link) {
                isCurrentPage = setActiveMenu(linkValues.link, linkValues.id, false);
                if (Menu.length) {
                    Menu.forEach((m: any) => {
                        let isSubMenuActive = false;
                        const subLinkValues = assignLinkValues(m);
                        isSubMenuActive = setActiveMenu(subLinkValues.link, subLinkValues.id, true);

                        // Insert My Details in My Profile
                        if ((Name.includes("MyAccount") || Name.includes("My Account")) && !myDetailsIsInserted) {
                            myDetails && subLinks.unshift({ ...myDetails });
                            myDetailsIsInserted = true;
                        }
                        // Add Security and Share/Delegate to My Details dropdown
                        if (
                            (m.Text.includes("Share/Delegate") || m.Text.includes("Security")) &&
                            (Name.includes("MyAccount") || Name.includes("My Account"))
                        ) {
                            _myProfileSubLinks.push({ ...subLinkValues });
                        }

                        subLinks.push({
                            ...subLinkValues,
                            active: isSubMenuActive,
                        });
                    });

                    // If current page is not coming from the sub-menu
                    // Initialize isCurrentPage to false again
                    if (subLinks.filter((sl) => sl.active).length === 0 && isCurrentPage) {
                        isCurrentPage = false;
                    }
                }
                links.push({
                    ...linkValues,
                    external: Menu.length > 0 ? false : linkValues.external,
                    subLinks,
                    active: isCurrentPage,
                });

                if (isCurrentPage && !hasActive) {
                    hasActive = true;
                    activePage = linkValues.id;
                }
            } else console.error(`${linkValues.label} doesn't have URL value.`);
        }
    });
    // formLinks = _myProfileSubLinks.concat(formLinks);
    formLinks.splice(myDetailsIndx + 1, 1, ..._myProfileSubLinks);
    links.concat(formLinks);

    return {
        links,
        formLinks,
        activePage,
    };
}

export const getNavConfig = async () => {
    // const configApi = APP_CONTAINER?.dataset.config || "/Config/Dashboard";
    // const apiMethod = APP_CONTAINER?.dataset.configMethod || "post";

    let res: AxiosResponse<any, any> ;
    // if (apiMethod === "get") {
    //     res = await axios.get(`${configApi}`);
    // } else {
    //     res = await axios.post(`${configApi}`);
    // }
    // return res;
    // return res = await axios.get("https://dashboard.testaffinitylogon.com/Config/Dashboard");
    return res = await axios.get(`${process.env.REACT_APP_LEGWAPI_URL}/Config/GetDashboardItems`).then((res) => res.data);
    const mockResponse: AxiosResponse = {
        data: mockConfigDashboard,
        status: 200,
        statusText: 'OK',
        headers: {},
        config: {},
      };
    return mockResponse
};

export const wrapPromise = (promise: Promise<any>) => {
    let status = "pending";
    let result: any;
    let suspender = promise.then(
        (res) => {
            status = "success";
            result = res;
        },
        (err) => {
            status = "error";
            result = err;
        }
    );

    return {
        read() {
            if (status === "pending") {
                throw suspender;
            } else if (status === "error") {
                throw result;
            }
            return result;
        },
    };
};

function assignLinkValues(link: any) {
    const { Icon, Text, Enabled, External, Url, Name } = link;
    return {
        id: Name,
        icon: Icon,
        label: Text,
        link: Url,
        enabled: Enabled,
        external: External,
    };
}

function setActiveMenu(url: string, name: string, isSubMenu: boolean) {
    try {
        var path = new URL(url).pathname.toLowerCase();
        const { app, appname } = window.Affinity;
        const ID: string = (app || appname).toLowerCase();
        const winPathname = window.location.pathname.toLowerCase();

        return (
            (!isSubMenu && (ID === name || ID === name.toLowerCase() || ID.includes(name.toLowerCase()))) ||
            (path !== "/" && path === winPathname) ||
            (path === "/cleverforms/inbox" && winPathname === "/inbox") ||
            (path === "/cleverforms/template" && winPathname === "/template") ||
            (path === "/cleverforms/admin" && winPathname === "/admin")
        );
    } catch (e) {
        return false;
    }
}
