import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const LockIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                d="M16.9234 9.4478V7.3008C16.9234 4.7878 14.8854 2.7498 12.3724 2.7498C9.8594 2.7388 7.8134 4.7668 7.8024 7.2808V7.3008V9.4478"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.1832 21.2496H8.54221C6.44821 21.2496 4.75021 19.5526 4.75021 17.4576V13.1686C4.75021 11.0736 6.44821 9.3766 8.54221 9.3766H16.1832C18.2772 9.3766 19.9752 11.0736 19.9752 13.1686V17.4576C19.9752 19.5526 18.2772 21.2496 16.1832 21.2496Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M12.3629 14.2027V16.4237" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
