import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const EditIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg
            className={_classList}
            width={width || "24"}
            height={height || "24"}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                {..._fillCommonProps}
                d="M5.0893 14.4877L15.0221 4.55488C16.0365 3.54052 17.6819 3.54052 18.6962 4.55488L20.023 5.88158C21.0373 6.89594 21.0373 8.54135 20.023 9.5557L10.051 19.5277C9.6073 19.9713 9.00589 20.2202 8.37801 20.2202H4.29413L4.39683 16.1003C4.41272 15.4946 4.66048 14.9165 5.0893 14.4877Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M13.7837 5.8134L18.7613 10.7899" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M14.3519 20.2203H21.3941" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
