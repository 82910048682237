import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const CloseIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path d="M7.90381 6.90381L17.0962 16.0962" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M17.0962 6.90381L7.90379 16.0962" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
