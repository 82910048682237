import React from "react";
import { IAppIco } from "../../types";

import {
    ArchiveIco,
    ArrowDownIco,
    ArrowUpIco,
    BurgerMenuIco,
    CalendarIco,
    ClockIco,
    CloseIco,
    CreditCardIco,
    DashboardIco,
    DocsIco,
    DollarInteractiveIco,
    DollarRoundIco,
    DownSquareIco,
    FileDownloadIco,
    FolderIco,
    HelpIco,
    InfoSquareIco,
    LeftCircleIco,
    LockIco,
    LogoutIco,
    MenuBottomEdgeIco,
    MenuTopEdgeIco,
    MoneybagIco,
    PlaneIco,
    RightCircleIco,
    ScheduleIco,
    SearchIco,
    SettingIco,
    UpSquareIco,
    UserBossIco,
    UserIco,
    UsersIco,
    WalletIco,
    WorkIco,
    MailIco,
    CogIco,
    KeyIco,
    UserCheckIco,
    ShareIco,
    CycleIco,
    ChartIco,
    DeleteIco,
    PlusIco,
    StarIco,
    PaperFailIco,
    PaperPlusIco,
    PaperUploadIco,
    PaperMinusIco,
    UnlockIco,
    DiscoveryIco,
    ChatIco,
    BookmarkIco,
    GraphIco,
    HeartIco,
    NotificationIco,
    ScanIco,
    FacebookIco,
    InstaGramIco,
    LinkeInIco,
    MediumIco,
    TwitterIco,
    WeChatIco,
    AntCloudIco,
    ToolIco,
    DocCheckIco,
    PaperCheckIco,
    ClockSquareIco,
    ActivityIco,
    EditSquareIco,
    EditIco,
    TicketIco,
    CameraIco,
    ReminderIco,
    TwoUserIco,
    ShieldDoneIco,
    ShieldFailIco,
    BagIco,
    TicketStarIco,
    InfoCircleIco,
    DiscountIco,
    BagTwoIco,
    DangerIco,
    LocationIco,
} from "../../svg";

export const _strokeCommonProps = {
    strokeWidth: "1.5",
    strokeLinecap: "round" as any,
    strokeLinejoin: "round" as any,
};
export const _fillCommonProps = {
    fillRule: "evenodd" as any,
    clipRule: "evenodd" as any,
};

export const AppIcon: React.FC<IAppIco> = (iProps) => {
    switch (iProps.asset) {
        case "dashboard":
            return <DashboardIco {...iProps} />;

        case "clock":
            return <ClockIco {...iProps} />;

        case "plane":
            return <PlaneIco {...iProps} />;

        case "schedule":
            return <ScheduleIco {...iProps} />;

        case "calendar":
            return <CalendarIco {...iProps} />;

        case "wallet":
            return <WalletIco {...iProps} />;

        case "docs":
            return <DocsIco {...iProps} />;

        case "folder":
            return <FolderIco {...iProps} />;

        case "work":
            return <WorkIco {...iProps} />;

        case "setting":
            return <SettingIco {...iProps} />;

        case "help":
            return <HelpIco {...iProps} />;

        case "burger-menu":
            return <BurgerMenuIco {...iProps} />;

        case "up-square":
            return <UpSquareIco {...iProps} />;

        case "down-square":
            return <DownSquareIco {...iProps} />;

        case "info-square":
            return <InfoSquareIco {...iProps} />;

        case "file-download":
            return <FileDownloadIco {...iProps} />;

        case "arrow-up":
            return <ArrowUpIco {...iProps} />;

        case "arrow-down":
            return <ArrowDownIco {...iProps} />;

        case "credit-card":
            return <CreditCardIco {...iProps} />;

        case "close":
            return <CloseIco {...iProps} />;

        case "menu-edge-top":
            return <MenuTopEdgeIco {...iProps} />;

        case "menu-edge-bottom":
            return <MenuBottomEdgeIco {...iProps} />;

        case "left-circle":
            return <LeftCircleIco {...iProps} />;

        case "right-circle":
            return <RightCircleIco {...iProps} />;

        case "logout":
            return <LogoutIco {...iProps} />;

        case "search":
            return <SearchIco {...iProps} />;

        case "user":
            return <UserIco {...iProps} />;

        case "users":
            return <UsersIco {...iProps} />;

        case "user-boss":
            return <UserBossIco {...iProps} />;

        case "lock":
            return <LockIco {...iProps} />;

        case "archive":
            return <ArchiveIco {...iProps} />;

        case "dollar-round":
            return <DollarRoundIco {...iProps} />;

        case "moneybag":
            return <MoneybagIco {...iProps} />;

        case "dollar-interactive":
            return <DollarInteractiveIco {...iProps} />;

        case "mail":
            return <MailIco {...iProps} />;

        case "cog":
            return <CogIco {...iProps} />;

        case "key":
            return <KeyIco {...iProps} />;

        case "user-check":
            return <UserCheckIco {...iProps} />;

        case "share":
            return <ShareIco {...iProps} />;

        case "cycle":
            return <CycleIco {...iProps} />;

        case "delete":
            return <DeleteIco {...iProps} />;

        case "plus":
            return <PlusIco {...iProps} />;

        case "chart":
            return <ChartIco {...iProps} />;

        case "star":
            return <StarIco {...iProps} />;

        case "paper-fail":
            return <PaperFailIco {...iProps} />;

        case "paper-plus":
            return <PaperPlusIco {...iProps} />;

        case "paper-upload":
            return <PaperUploadIco {...iProps} />;

        case "paper-minus":
            return <PaperMinusIco {...iProps} />;

        case "unlock":
            return <UnlockIco {...iProps} />;

        case "discovery":
            return <DiscoveryIco {...iProps} />;

        case "graph":
            return <GraphIco {...iProps} />;

        case "bookmark":
            return <BookmarkIco {...iProps} />;

        case "notification":
            return <NotificationIco {...iProps} />;

        case "chat":
            return <ChatIco {...iProps} />;

        case "heart":
            return <HeartIco {...iProps} />;

        case "scan":
            return <ScanIco {...iProps} />;

        case "ant-cloud":
            return <AntCloudIco {...iProps} />;

        case "facebook":
            return <FacebookIco {...iProps} />;

        case "instagram":
            return <InstaGramIco {...iProps} />;

        case "linkedin":
            return <LinkeInIco {...iProps} />;

        case "medium":
            return <MediumIco {...iProps} />;

        case "twitter":
            return <TwitterIco {...iProps} />;

        case "wechat":
            return <WeChatIco {...iProps} />;

        case "tool":
            return <ToolIco {...iProps} />;

        case "doc-check":
            return <DocCheckIco {...iProps} />;

        case "paper-check":
            return <PaperCheckIco {...iProps} />;

        case "clock-square":
            return <ClockSquareIco {...iProps} />;

        case "edit":
            return <EditIco {...iProps} />;

        case "edit-square":
            return <EditSquareIco {...iProps} />;

        case "activity":
            return <ActivityIco {...iProps} />;

        case "two-user":
            return <TwoUserIco {...iProps} />;

        case "ticket":
            return <TicketIco {...iProps} />;

        case "camera":
            return <CameraIco {...iProps} />;

        case "reminder":
            return <ReminderIco {...iProps} />;

        case "shield-done":
            return <ShieldDoneIco {...iProps} />;

        case "shield-fail":
            return <ShieldFailIco {...iProps} />;

        case "ticket-star":
            return <TicketStarIco {...iProps} />;

        case "bag":
            return <BagIco {...iProps} />;

        case "bag-two":
            return <BagTwoIco {...iProps} />;

        case "discount":
            return <DiscountIco {...iProps} />;

        case "info-circle":
            return <InfoCircleIco {...iProps} />;

        case "danger":
            return <DangerIco {...iProps} />;

        case "location":
            return <LocationIco {...iProps} />;

        default:
            return <SettingIco {...iProps} />;
    }
};
