import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const DocsIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path d="M16.2162 16.2234H8.99622" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M16.2162 12.0369H8.99622" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M11.7513 7.86008H8.99631" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                {..._fillCommonProps}
                d="M16.4086 2.74979C16.4086 2.74979 8.73161 2.75379 8.71961 2.75379C5.95961 2.77079 4.25061 4.58679 4.25061 7.35679V16.5528C4.25061 19.3368 5.97261 21.1598 8.75661 21.1598C8.75661 21.1598 16.4326 21.1568 16.4456 21.1568C19.2056 21.1398 20.9156 19.3228 20.9156 16.5528V7.35679C20.9156 4.57279 19.1926 2.74979 16.4086 2.74979Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
