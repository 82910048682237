import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const ShareIco: React.FC<IAppIco> = ({ asset, stroke, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <g>
                <g>
                    <path
                        {..._fillCommonProps}
                        d="m 8.73356,3.4321278 c -1.4195776,0 -2.5594973,1.1397464 -2.5594973,2.5594974 0,1.4194186 1.1400736,2.5594975 2.5594973,2.5594975 1.419756,0 2.559498,-1.1399344 2.559498,-2.5594975 0,-1.419905 -1.139598,-2.5594974 -2.559498,-2.5594974 z m 0,0.7959338 c 1.0211999,0 1.745064,0.7423684 1.745064,1.7635636 0,1.020892 -0.7240571,1.7543131 -1.745064,1.7543131 -1.0207138,0 -1.7450636,-0.7335992 -1.7450636,-1.7543131 0,-1.0210122 0.7241717,-1.7635636 1.7450636,-1.7635636 z"
                        fill={fill || "currentColor"}
                        strokeWidth="0.6"
                    />
                    <g fill="none" transform="matrix(0.88572648,-0.37342262,0.51724764,0.63944287,14.451103,3.9190601)">
                        <path
                            d="m -5.3130463,2.2255638 2.4493576,5.8997271"
                            stroke={stroke || "currentColor"}
                            {..._strokeCommonProps}
                        />
                        <path
                            d="m -10.767075,11.994985 6.2020618,-0.419749"
                            stroke={stroke || "currentColor"}
                            {..._strokeCommonProps}
                        />
                    </g>
                    <path
                        {..._fillCommonProps}
                        d="m 18.279501,8.8680112 c -1.419577,0 -2.559497,1.1397468 -2.559497,2.5594968 0,1.419419 1.140074,2.559498 2.559497,2.559498 1.419756,0 2.559498,-1.139935 2.559498,-2.559498 0,-1.419904 -1.139598,-2.5594968 -2.559498,-2.5594968 z m 0,0.7959338 c 1.0212,0 1.745064,0.742369 1.745064,1.763563 0,1.020892 -0.724057,1.754313 -1.745064,1.754313 -1.020713,0 -1.745063,-0.733599 -1.745063,-1.754313 0,-1.021011 0.724171,-1.763563 1.745063,-1.763563 z"
                        fill={fill || "currentColor"}
                        strokeWidth="0.6"
                    />
                    <path
                        {..._fillCommonProps}
                        d="m 8.691113,13.724705 c -1.419577,0 -2.559497,1.139747 -2.559497,2.559497 0,1.419419 1.140074,2.559498 2.559497,2.559498 1.419756,0 2.559498,-1.139935 2.559498,-2.559498 0,-1.419904 -1.139598,-2.559497 -2.559498,-2.559497 z m 0,0.795934 c 1.0212,0 1.745064,0.742369 1.745064,1.763563 0,1.020892 -0.724057,1.754313 -1.745064,1.754313 -1.020713,0 -1.745063,-0.733599 -1.745063,-1.754313 0,-1.021011 0.724171,-1.763563 1.745063,-1.763563 z"
                        fill={fill || "currentColor"}
                        strokeWidth="0.6"
                    />
                </g>
            </g>
        </svg>
    );
};
