import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const PaperCheckIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M 15.1063,2.0119 C 15.0632,2.00421 15.0189,2.0002 14.9736,2.0002 c -0.0453,0 -0.0896,0.00401 -0.1327,0.0117 H 8.5843 c -2.48729,0 -4.584,2.01783 -4.584,4.479 v 10.849 c 0,2.5822 1.98545,4.525 4.584,4.525 h 7.988 c 2.4686,0 4.48,-2.045 4.48,-4.525 v -9.302 c 0,-0.19364 -0.0749,-0.37978 -0.209,-0.51946 l -5.066,-5.276 C 15.6359,2.09516 15.4405,2.0119 15.2363,2.0119 Z m -0.8827,1.4992 -5.6393,8e-4 c -1.67146,0 -3.084,1.3594 -3.084,2.979 v 10.849 c 0,1.7464 1.30667,3.025 3.084,3.025 h 7.988 c 1.6345,0 2.98,-1.3679 2.98,-3.025 L 19.552,8.98328 18.6755,8.98627 C 18.342,8.98586 17.9627,8.98516 17.541,8.9842 15.7707,8.98046 14.3264,7.59367 14.2288,5.8476 L 14.2236,5.6592 Z M 18.731,7.48404 17.5443,7.4842 C 16.5379,7.48207 15.7236,6.66576 15.7236,5.6592 V 4.35205 Z"
                fill={fill || "currentColor"}
            />
            <path
                {..._fillCommonProps}
                d="m 16.563509,10.815309 c -0.2929,-0.29289 -0.7677,-0.29289 -1.0606,0 l -3.886112,3.471532 -1.6693833,-1.536499 -0.08413,-0.0726 c -0.29366,-0.2178 -0.71031,-0.1935 -0.97653,0.0728 -0.29283,0.2929 -0.29273,0.7678 2.3e-4,1.0606 l 2.2005133,2.067299 0.0841,0.0726 c 0.2936,0.2178 0.7102,0.1936 0.9764,-0.0727 l 4.415512,-4.002402 0.0726,-0.0841 c 0.2179,-0.2936 0.1937,-0.71026 -0.0726,-0.97653 z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
