import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const KeyIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M16.8344 2.75021H8.1654C5.1444 2.75021 3.2504 4.88921 3.2504 7.91621V16.0842C3.2504 19.1112 5.1354 21.2502 8.1654 21.2502H16.8334C19.8644 21.2502 21.7504 19.1112 21.7504 16.0842V7.91621C21.7504 4.88921 19.8644 2.75021 16.8344 2.75021Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                {..._fillCommonProps}
                d="M11.1923 12.0002C11.1923 13.0222 10.3633 13.8522 9.3403 13.8522C8.3183 13.8522 7.4893 13.0222 7.4893 12.0002C7.4893 10.9782 8.3183 10.1482 9.3403 10.1482C10.3633 10.1482 11.1923 10.9782 11.1923 12.0002Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M11.1923 12.0002H17.5103V13.8522" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M14.6816 13.8517V11.9997" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
