import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const TicketIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path d="M14.3497 4.25031V6.67031" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M14.3497 17.7601V19.7841" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M14.3497 14.3246V9.5036" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                {..._fillCommonProps}
                d="M19.2021 20C21.0242 20 22.5 18.5426 22.5 16.7431V14.1506C21.2943 14.1506 20.3233 13.1917 20.3233 12.001C20.3233 10.8104 21.2943 9.85039 22.5 9.85039L22.499 7.25686C22.499 5.45745 21.0221 4 19.2011 4H5.79892C3.97789 4 2.50104 5.45745 2.50104 7.25686L2.5 9.93485C3.70567 9.93485 4.67668 10.8104 4.67668 12.001C4.67668 13.1917 3.70567 14.1506 2.5 14.1506V16.7431C2.5 18.5426 3.9758 20 5.79787 20H19.2021Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
