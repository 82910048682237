import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const MailIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                d="M17.7677 9.06113L13.5023 12.4954C12.6951 13.1283 11.5635 13.1283 10.7563 12.4954L6.45422 9.06113"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                {..._fillCommonProps}
                d="M7.38787 3.5H16.8158C18.1752 3.51525 19.469 4.08993 20.396 5.0902C21.323 6.09048 21.8022 7.42903 21.722 8.79412V15.322C21.8022 16.6871 21.323 18.0256 20.396 19.0259C19.469 20.0262 18.1752 20.6009 16.8158 20.6161H7.38787C4.46796 20.6161 2.5 18.2407 2.5 15.322V8.79412C2.5 5.87545 4.46796 3.5 7.38787 3.5Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
