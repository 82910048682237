import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const CalendarIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path d="M3.59265 9.40427H21.4166" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M16.9421 13.3097H16.9514" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M12.5046 13.3097H12.5139" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M8.0579 13.3097H8.06717" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M16.9421 17.1962H16.9514" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M12.5046 17.1962H12.5139" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M8.0579 17.1962H8.06717" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M16.5437 2V5.29078" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M8.46552 2V5.29078" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7383 3.57919H8.27096C5.33427 3.57919 3.5 5.21513 3.5 8.22222V17.2719C3.5 20.3262 5.33427 22 8.27096 22H16.729C19.675 22 21.5 20.3546 21.5 17.3475V8.22222C21.5092 5.21513 19.6842 3.57919 16.7383 3.57919Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
