import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const ReminderIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg
            className={_classList}
            width={width || "24"}
            height={height || "24"}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.211 11.4923L21.211 7.7533C21.211 4.6783 19.034 2.75031 15.952 2.7503L7.638 2.7503C4.556 2.7503 2.379 4.6693 2.379 7.7533L2.379 16.5773C2.379 19.6623 4.556 21.5813 7.638 21.5813L11.666 21.5813"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M3.14948 7.97034L20.9734 7.97034" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M16.1006 1.5661L16.1006 4.85688" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M8.0224 1.5661L8.0224 4.85688" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                {..._fillCommonProps}
                d="M13.5004 17.3042V17.2057C13.5148 16.9141 13.6083 16.6316 13.7711 16.3873C14.042 16.0939 14.2275 15.7342 14.3081 15.3462C14.3081 15.0463 14.3081 14.7421 14.3343 14.4422C14.4696 12.9983 15.8973 12 17.3075 12H17.3424C18.7526 12 20.1803 12.9983 20.32 14.4422C20.3462 14.7421 20.32 15.0463 20.3418 15.3462C20.4234 15.7351 20.6087 16.0959 20.8788 16.3916C21.0428 16.6337 21.1364 16.9152 21.1495 17.2057V17.2999C21.1593 17.6916 21.0243 18.0736 20.7697 18.3753C20.4331 18.7282 19.9765 18.9477 19.4861 18.9923C18.0481 19.1465 16.5974 19.1465 15.1594 18.9923C14.6696 18.9457 14.2136 18.7266 13.8758 18.3753C13.6251 18.0733 13.492 17.6937 13.5004 17.3042Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M16.2247 21.4833C16.4494 21.7653 16.7793 21.9478 17.1415 21.9904C17.5037 22.0331 17.8682 21.9323 18.1545 21.7104C18.2425 21.6448 18.3217 21.5685 18.3902 21.4833"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
