import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const EditSquareIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg
            className={_classList}
            width={width || "25"}
            height={height || "24"}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.9923 2.789H8.2533C5.1783 2.789 3.25031 4.966 3.25031 8.048V16.362C3.25031 19.444 5.1693 21.621 8.2533 21.621H17.0773C20.1623 21.621 22.0813 19.444 22.0813 16.362V12.334"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                {..._fillCommonProps}
                d="M9.32782 10.9209L16.8008 3.4479C17.7318 2.5179 19.2408 2.5179 20.1718 3.4479L21.3888 4.6649C22.3198 5.5959 22.3198 7.1059 21.3888 8.0359L13.8798 15.5449C13.4728 15.9519 12.9208 16.1809 12.3448 16.1809H8.59882L8.69282 12.4009C8.70682 11.8449 8.93382 11.3149 9.32782 10.9209Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M15.6652 4.60249L20.2312 9.16849" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
