import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const BagTwoIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                d="M16.2729 9.30504V6.27304C16.2729 4.18904 14.5839 2.50004 12.5009 2.50004C10.4169 2.49104 8.71991 4.17204 8.71091 6.25604V6.27304V9.30504"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                {..._fillCommonProps}
                d="M17.2422 21.0003H7.75778C5.40569 21.0003 3.5 19.0953 3.5 16.7453V11.2293C3.5 8.87933 5.40569 6.97433 7.75778 6.97433H17.2422C19.5943 6.97433 21.5 8.87933 21.5 11.2293V16.7453C21.5 19.0953 19.5943 21.0003 17.2422 21.0003Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
