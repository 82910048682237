import React from "react";
import { IAppIco } from "../types";

export const DiscoveryIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg
            className={_classList}
            width={width || "25"}
            height={height || "24"}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2ZM12.5 3.44774C17.2233 3.44774 21.0523 7.27672 21.0523 12C21.0523 16.7233 17.2233 20.5523 12.5 20.5523C7.77672 20.5523 3.94774 16.7233 3.94774 12C3.94774 7.27672 7.77672 3.44774 12.5 3.44774ZM15.5084 8.08461L10.5964 9.6218C10.37 9.69266 10.1927 9.87 10.1218 10.0964L8.58461 15.0084C8.41011 15.566 8.93404 16.0899 9.49164 15.9154L14.4036 14.3782C14.63 14.3073 14.8073 14.13 14.8782 13.9036L16.4154 8.99164C16.5899 8.43404 16.066 7.91011 15.5084 8.08461ZM14.6204 9.87856L13.609 13.109L10.3786 14.1204L11.3901 10.8901L14.6204 9.87856Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
