import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const InfoCircleIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M2.49989 12.0002C2.49989 6.47799 6.97768 2.0002 12.4999 2.0002C18.0228 2.0002 22.4999 6.47765 22.4999 12.0002C22.4999 17.5227 18.0228 22.0002 12.4999 22.0002C6.97768 22.0002 2.49989 17.5224 2.49989 12.0002ZM20.9999 12.0002C20.9999 7.30606 17.1943 3.5002 12.4999 3.5002C7.80611 3.5002 3.99989 7.30641 3.99989 12.0002C3.99989 16.694 7.80611 20.5002 12.4999 20.5002C17.1943 20.5002 20.9999 16.6943 20.9999 12.0002ZM12.495 7.4543C12.8747 7.4543 13.1885 7.73645 13.2381 8.10253L13.245 8.2043V12.6233C13.245 13.0375 12.9092 13.3733 12.495 13.3733C12.1153 13.3733 11.8015 13.0911 11.7518 12.7251L11.745 12.6233V8.2043C11.745 7.79009 12.0808 7.4543 12.495 7.4543ZM13.255 15.7961C13.255 15.3819 12.9192 15.0461 12.505 15.0461L12.3932 15.0529C12.0271 15.1026 11.745 15.4164 11.745 15.7961C11.745 16.2103 12.0808 16.5461 12.495 16.5461L12.6068 16.5393C12.9728 16.4896 13.255 16.1758 13.255 15.7961Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
