import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const DangerIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M14.8799 4.2979C14.0873 3.05582 12.4449 2.62624 11.1301 3.35491C10.6663 3.60988 10.2819 3.99392 10.0243 4.46014L2.7525 17.1868L2.68779 17.358C2.17376 18.8448 2.87086 20.3951 4.26377 20.9625L4.48678 21.0419C4.71225 21.1118 4.94472 21.1535 5.18074 21.1667L5.264 21.165L5.2957 21.1679H19.7777L19.9538 21.1536C21.4645 20.9864 22.574 19.6979 22.4967 18.1954C22.4787 17.8746 22.4096 17.5637 22.2903 17.2737L14.9709 4.45082L14.8799 4.2979ZM11.855 4.66814C12.4995 4.31094 13.3079 4.54333 13.6634 5.18597L20.9455 17.931L20.9671 18.0285C20.98 18.0953 20.9938 18.1845 20.9989 18.2759C21.0364 19.0056 20.4726 19.6305 19.7385 19.6689L5.2957 19.6679L5.265 19.669L5.11438 19.6526C5.01543 19.6363 4.91917 19.6094 4.82691 19.5722C4.1496 19.2963 3.82244 18.5212 4.09861 17.841L11.3319 5.19498L11.4138 5.06423C11.5312 4.89784 11.6811 4.76372 11.855 4.66814ZM11.7503 16.2696C11.7503 15.8556 12.0863 15.5136 12.5003 15.5136C12.9143 15.5136 13.2503 15.8446 13.2503 16.2586V16.2696C13.2503 16.6836 12.9143 17.0196 12.5003 17.0196C12.0863 17.0196 11.7503 16.6836 11.7503 16.2696ZM13.2331 10.1943C13.1834 9.82825 12.8696 9.5461 12.4899 9.5461C12.0757 9.5461 11.7399 9.88189 11.7399 10.2961V13.3961L11.7467 13.4979C11.7964 13.8639 12.1102 14.1461 12.4899 14.1461C12.9041 14.1461 13.2399 13.8103 13.2399 13.3961V10.2961L13.2331 10.1943Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
