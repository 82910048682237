import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ConfigProvider } from "antd";

import './index.less';
// import App from './App';
// import NavBar from './NavBar';
import Cards from './component/Cards/Cards';

import reportWebVitals from './reportWebVitals';

// import 'antd/dist/antd.less';

ReactDOM.render(
    <ConfigProvider prefixCls="ss">
         <BrowserRouter>
            {/*<NavBar />*/}
            <Routes>
                {/* <Route path="/" element={<App />}></Route> */}
                <Route path="/" element={<Cards />}></Route>
                {/*<Route path="/page1" element={<Page1 />}></Route>*/}
            </Routes>
        </BrowserRouter>
        {/* <React.StrictMode>
            <App />
        </React.StrictMode> */}
    </ConfigProvider>,
  
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
