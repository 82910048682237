import React from "react";
import { Spin, SpinProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface Props extends SpinProps {
    position?: "top" | "middle" | "bottom";
    isAppWrap?: boolean;
}

const antico = <LoadingOutlined style={{ fontSize: 64 }} spin />;

export const AppLoader: React.FC<Props> = ({
    position,
    isAppWrap,
    ...props
}) => (
    <Spin
        {...props}
        indicator={antico}
        wrapperClassName={"ss-dashboard-wrap-loader-overlay"}
        className={`ss-dashboard-wrap-loader ss-dashboard-wrap-${
            position || "middle"
        }-loader${isAppWrap ? " app-wrapper" : ""}`}
    >
        {props.children}
    </Spin>
);
