import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const SettingIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3066 7.62358L20.6842 6.54349C20.1576 5.62957 18.9907 5.31429 18.0755 5.83869V5.83869C17.6399 6.09531 17.1201 6.16812 16.6307 6.04106C16.1413 5.91399 15.7226 5.59749 15.4668 5.16134C15.3023 4.88412 15.2139 4.56836 15.2105 4.24601V4.24601C15.2254 3.72919 15.0304 3.22837 14.67 2.85764C14.3096 2.48691 13.8145 2.27783 13.2975 2.27805H12.0435C11.5369 2.27804 11.0513 2.47988 10.694 2.83891C10.3367 3.19795 10.1371 3.68456 10.1396 4.19109V4.19109C10.1246 5.23689 9.27245 6.07678 8.22654 6.07667C7.90418 6.07332 7.58843 5.98491 7.3112 5.82038V5.82038C6.39603 5.29598 5.22908 5.61126 4.70251 6.52519L4.03432 7.62358C3.50838 8.53636 3.81937 9.70258 4.72997 10.2323V10.2323C5.32187 10.574 5.6865 11.2056 5.6865 11.889C5.6865 12.5725 5.32187 13.204 4.72997 13.5458V13.5458C3.82053 14.0719 3.5092 15.2353 4.03432 16.1453V16.1453L4.66589 17.2346C4.91262 17.6798 5.32657 18.0083 5.81616 18.1474C6.30575 18.2866 6.83061 18.2249 7.27459 17.976V17.976C7.71105 17.7213 8.23116 17.6515 8.71931 17.7822C9.20746 17.9128 9.62321 18.233 9.87413 18.6716C10.0387 18.9489 10.1271 19.2646 10.1304 19.587V19.587C10.1304 20.6435 10.9869 21.5 12.0435 21.5H13.2975C14.3505 21.5 15.2055 20.6491 15.2105 19.5961V19.5961C15.2081 19.088 15.4088 18.6 15.7681 18.2407C16.1274 17.8814 16.6154 17.6806 17.1236 17.6831C17.4451 17.6917 17.7596 17.7797 18.0389 17.9394V17.9394C18.9517 18.4653 20.1179 18.1543 20.6476 17.2437V17.2437L21.3066 16.1453C21.5617 15.7075 21.6317 15.186 21.5012 14.6963C21.3706 14.2067 21.0502 13.7893 20.611 13.5366V13.5366C20.1717 13.2839 19.8514 12.8665 19.7208 12.3769C19.5902 11.8873 19.6602 11.3658 19.9153 10.9279C20.0812 10.6383 20.3213 10.3982 20.611 10.2323V10.2323C21.5161 9.70286 21.8264 8.54346 21.3066 7.63274V7.63274V7.62358Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <circle cx="12.675" cy="11.889" r="2.63616" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
