import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const DollarInteractiveIco: React.FC<IAppIco> = ({ asset, fill, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "20"} height={height || "22"} viewBox="0 0 20 22" fill="none">
            <path
                d="M5.70565 1H14.2941C16.9169 1.02857 19.0255 3.4 18.9998 6.31429V12.7571C18.9998 14.1571 18.5241 15.5 17.6369 16.5C16.7498 17.5 15.5541 18.0571 14.2941 18.0714H5.70565L1 21V6.31429C1 4.91429 1.47571 3.57143 2.36284 2.57143C3.24997 1.57143 4.44567 1.01429 5.70565 1V1Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <g fill="inherit" transform="translate(7.5681595,5.2896787)">
                <path
                    d="m 4.4986,4.91584 c 0.28956,0.29703 0.45989,0.66832 0.45989,1.12871 0,0.75743 -0.47692,1.23268 -1.02198,1.5 C 3.68102,7.66337 3.4085,7.75248 3.153,7.79703 V 8.40594 C 3.153,8.73267 2.84641,9 2.47169,9 2.09696,9 1.79037,8.73267 1.79037,8.40594 V 7.78218 C 1.31345,7.66337 0.887629,7.41089 0.581037,7.08416 0.138182,6.60891 -0.0662124,5.9703 0.018952,5.36139 0.0700507,5.03465 0.410708,4.81188 0.785432,4.85644 1.16015,4.90099 1.41565,5.19802 1.36455,5.52475 1.27939,5.98515 1.44971,6.11881 1.63708,6.34158 1.75631,6.4901 1.97773,6.65347 2.48872,6.74257 c 0.0511,0 0.47692,0.07426 0.80054,-0.19307 C 3.56179,6.31188 3.62992,5.94059 3.49366,5.70297 3.37443,5.5099 3.153,5.46535 2.93157,5.36139 2.38652,5.12376 1.44971,4.81188 0.683234,4.15842 0.564004,4.06931 0.342577,3.90594 0.206314,3.62376 0.0530178,3.34158 0.0359849,3.08911 0.0359849,2.95545 V 2.92574 C 0.018952,2.5396 0.121149,2.21287 0.325544,1.93069 0.529938,1.66337 0.802464,1.4703 1.07499,1.35149 1.31345,1.24752 1.58598,1.18812 1.82444,1.15842 V 0.594059 C 1.82444,0.267327 2.114,0 2.50575,0 2.89751,0 3.18707,0.252475 3.18707,0.594059 V 1.18812 C 3.64696,1.29208 4.05574,1.5 4.37937,1.81188 4.85629,2.27228 5.07772,2.89604 4.97552,3.5198 4.92442,3.84653 4.58376,4.05446 4.20904,4.0099 3.83432,3.96535 3.59586,3.66832 3.62992,3.34158 3.66399,3.10396 3.62992,2.9703 3.59586,2.88119 3.56179,2.82178 3.52773,2.73267 3.37443,2.59901 3.153,2.39109 2.88048,2.15347 2.47169,2.13861 2.42059,2.13861 1.92663,2.12376 1.68817,2.42079 1.44971,2.71782 1.56894,3.14851 1.75631,3.41584 1.84147,3.5495 1.97773,3.66832 3.54476,4.33663 3.80025,4.44059 4.17497,4.60396 4.51563,4.94554 Z"
                    fill={fill || "currentColor"}
                />
            </g>
        </svg>
    );
};
