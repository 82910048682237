import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const UsersIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                d="M18.0949 10.9319C19.6972 10.9319 20.997 9.63295 20.997 8.03061C20.997 6.42828 19.6972 5.12936 18.0949 5.12936"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M19.4291 14.0847C19.9076 14.1177 20.3833 14.1856 20.8517 14.291C21.5026 14.4184 22.2854 14.6852 22.5641 15.2691C22.7419 15.6431 22.7419 16.0785 22.5641 16.4534C22.2863 17.0373 21.5026 17.3032 20.8517 17.437"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M6.78998 10.9319C5.18765 10.9319 3.88782 9.63295 3.88782 8.03061C3.88782 6.42828 5.18765 5.12936 6.78998 5.12936"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M5.45578 14.0847C4.97728 14.1177 4.50153 14.1856 4.03312 14.291C3.38228 14.4184 2.59945 14.6852 2.3217 15.2691C2.14295 15.6431 2.14295 16.0785 2.3217 16.4534C2.59853 17.0373 3.38228 17.3032 4.03312 17.437"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4378 14.7096C15.6846 14.7096 18.4584 15.2009 18.4584 17.1671C18.4584 19.1325 15.7029 19.6421 12.4378 19.6421C9.19002 19.6421 6.4171 19.1508 6.4171 17.1846C6.4171 15.2183 9.1726 14.7096 12.4378 14.7096Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4378 11.9049C10.2964 11.9049 8.57951 10.188 8.57951 8.04575C8.57951 5.90442 10.2964 4.1875 12.4378 4.1875C14.5791 4.1875 16.296 5.90442 16.296 8.04575C16.296 10.188 14.5791 11.9049 12.4378 11.9049Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
