import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const ChatIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M12.558 2.00024C9.04635 1.98613 5.78554 3.80701 3.96059 6.8001C2.13541 9.79358 2.01288 13.5227 3.63765 16.6292L3.82147 16.9875C3.89175 17.1192 3.90531 17.2613 3.86458 17.3927C3.60204 18.0865 3.37136 18.8357 3.1873 19.597L3.16776 19.7609C3.16776 20.5263 3.57616 21.0523 4.38671 21.0343L4.52167 21.018C5.25798 20.8554 5.9831 20.646 6.69261 20.3909C6.78665 20.3671 6.9407 20.3763 7.07971 20.4332L7.76224 20.8194C7.76378 20.8242 7.76493 20.8278 7.77208 20.8319L7.81788 20.848C11.4929 22.7804 15.9812 22.2473 19.0997 19.5079C22.2187 16.7681 23.3199 12.3901 21.8675 8.50387C20.4153 4.61803 16.7115 2.03077 12.558 2.00024ZM12.2675 3.39903L12.5484 3.39463C16.1231 3.42182 19.3109 5.64861 20.5605 8.99235C21.81 12.3357 20.8626 16.1021 18.1788 18.4596L17.9587 18.6466C15.3626 20.778 11.7582 21.2076 8.73269 19.7468L8.44496 19.6026L8.45729 19.6042L8.43909 19.5993L8.01677 19.3569C7.86471 19.271 7.74408 19.2059 7.64685 19.159C7.1837 18.9681 6.71354 18.9399 6.27133 19.0617L5.85272 19.2055C5.57605 19.2969 5.30372 19.3794 5.03273 19.4541L4.63767 19.5573L4.54356 19.9249C4.71419 19.2192 4.92804 18.5246 5.18389 17.8451C5.34853 17.3183 5.29818 16.7905 5.05755 16.3402L4.87662 15.9874C3.47627 13.3099 3.58168 10.1019 5.15196 7.52649C6.67338 5.03121 9.35494 3.48236 12.2675 3.39903ZM6.9746 12.0002C6.9746 11.37 7.48586 10.8596 8.11596 10.8596C8.74606 10.8596 9.25732 11.37 9.25732 12.0002C9.25732 12.6304 8.74606 13.1408 8.11596 13.1408C7.48586 13.1408 6.9746 12.6304 6.9746 12.0002ZM11.4115 12.0002C11.4115 11.37 11.9227 10.8596 12.5528 10.8596C13.1829 10.8596 13.6942 11.37 13.6942 12.0002C13.6942 12.6304 13.1829 13.1408 12.5528 13.1408C11.9227 13.1408 11.4115 12.6304 11.4115 12.0002ZM16.9897 10.8596C16.3596 10.8596 15.8484 11.37 15.8484 12.0002C15.8484 12.6304 16.3596 13.1408 16.9897 13.1408C17.6198 13.1408 18.1311 12.6304 18.1311 12.0002C18.1311 11.37 17.6198 10.8596 16.9897 10.8596Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
