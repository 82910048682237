import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const LogoutIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                d="M15.516 7.38951V6.45651C15.516 4.42151 13.866 2.77151 11.831 2.77151H6.956C4.922 2.77151 3.272 4.42151 3.272 6.45651V17.5865C3.272 19.6215 4.922 21.2715 6.956 21.2715H11.841C13.87 21.2715 15.516 19.6265 15.516 17.5975V16.6545"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M22.3095 12.0214H10.2685" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                d="M19.3812 9.10632L22.3092 12.0213L19.3812 14.9373"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
