import React from "react";
import { IAppIco } from "../types";

export const BurgerMenuIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "33"} height={height || "32"} viewBox="0 0 33 32" fill="none">
            <path d="M6.875 24H26.125" stroke={stroke || "currentColor"} strokeWidth="3" strokeLinecap="round" />
            <path d="M6.875 16H26.125" stroke={stroke || "currentColor"} strokeWidth="3" strokeLinecap="round" />
            <path d="M6.875 8H26.125" stroke={stroke || "currentColor"} strokeWidth="3" strokeLinecap="round" />
        </svg>
    );
};
