import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { AppIcon } from "./SVGFiles";
import { AppContext } from "../../context";

const APP_CONTAINER = document.getElementById("SS-DashboardWrapper");

export const UserTopNav: React.FC = () => {
    const { state } = React.useContext(AppContext);
    const { user, formLinks } = state;

    const userMenu = (
        <Menu className="ss-dashboard-wrap-dd-profile">
            {formLinks &&
                formLinks
                    .filter((nav) => nav.enabled)
                    .map((navProps, i) => {
                        return (
                            <Menu.Item key={i}>
                                <a role={"button"} href={navProps.link}>
                                    <AppIcon asset={navProps.icon} stroke="#000000" height="16" width="16" />
                                    <span>{navProps.label}</span>
                                </a>
                            </Menu.Item>
                        );
                    })}

            <Menu.Item key={formLinks?.length || "1"}>
                <a role={"button"} href={process.env.REACT_APP_LOGOUT_URL as string}>
                    <AppIcon asset={"logout"} stroke="#000000" height="16" width="16" />
                    <span>Logout</span>
                </a>
            </Menu.Item>
        </Menu>
    );

    const employeeName = React.useMemo(() => {
        if (user) {
            const { FirstName, LastName } = user;
            return <span>{[FirstName, LastName].join(" ")}</span>;
        }
        return "";
    }, [user]);

    return (
        <div className="ss-dashboard-wrap-user-top-nav">
            <span className="ss-dashboard-wrap-user-name">
                <span className="ss-dashboard-wrap-mobile-name">{employeeName}</span>
                <Dropdown
                    overlay={userMenu}
                    trigger={["click"]}
                    arrow
                    placement="bottomRight"
                    overlayClassName="ss-dashboard-wrap-profile-overlay"
                    getPopupContainer={() => APP_CONTAINER || document.body}>
                    <span className="ss-dashboard-wrap-user-dd">
                        {/* {employeeAcro} */}
                        <span className="m-r-8">{employeeName}</span>
                        <AppIcon asset="arrow-down" stroke="#636E7C" height="16" width="16" />
                    </span>
                </Dropdown>
            </span>

            <Button
                className="m-l-4 help-btn"
                type="link"
                shape="circle"
                icon={<AppIcon asset="help" height="30" width="30" />}
                onClick={() => window.open(process.env.REACT_APP_HELP_URL as string, "_blank")}
            />
        </div>
    );
};
