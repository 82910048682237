import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const ArrowDownIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path
                d="M19 8.5L12 15.5L5 8.5"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
                strokeWidth={"2"}
            />
        </svg>
    );
};
