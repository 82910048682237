import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const BagIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M7.98627 21.5H16.3341C19.4005 21.5 21.7529 20.3924 21.0847 15.9348L20.3066 9.89357C19.8947 7.66931 18.476 6.81805 17.2311 6.81805H7.05263C5.78948 6.81805 4.45309 7.73339 3.97712 9.89357L3.19909 15.9348C2.63158 19.889 4.91991 21.5 7.98627 21.5Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M7.84897 6.59839C7.84897 4.21232 9.78327 2.27802 12.1693 2.27802V2.27802C13.3183 2.27315 14.4219 2.72618 15.2361 3.53694C16.0503 4.34769 16.508 5.44938 16.508 6.59839V6.59839"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M9.20367 11.1018H9.24944" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M15.0343 11.1018H15.0801" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
