import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const CameraIco: React.FC<IAppIco> = ({ asset, stroke, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M18.1378 7.1099V7.1099C17.7648 7.1099 17.4268 6.8949 17.2678 6.5589C16.9808 5.9509 16.6158 5.1739 16.3998 4.7509C16.0808 4.1219 15.5638 3.7559 14.8478 3.7509C14.8358 3.7499 10.1638 3.7499 10.1518 3.7509C9.4358 3.7559 8.9198 4.1219 8.5998 4.7509C8.3848 5.1739 8.0198 5.9509 7.7328 6.5589C7.5738 6.8949 7.2348 7.1099 6.8628 7.1099V7.1099C4.8668 7.1099 3.2498 8.7269 3.2498 10.7219V16.6579C3.2498 18.6519 4.8668 20.2699 6.8628 20.2699H18.1378C20.1328 20.2699 21.7498 18.6519 21.7498 16.6579V10.7219C21.7498 8.7269 20.1328 7.1099 18.1378 7.1099Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                {..._fillCommonProps}
                d="M9.3214 13.3345C9.3204 15.0875 10.7514 16.5215 12.5014 16.5205C14.2484 16.5185 15.6754 15.0905 15.6784 13.3415C15.6814 11.5855 14.2554 10.1545 12.5034 10.1525C10.7414 10.1505 9.3074 11.6055 9.3214 13.3345Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                className="fill-path"
                d="M17.4025 10.9951C17.3059 10.9852 17.2105 10.96 17.1036 10.9169C16.9847 10.8641 16.8818 10.7969 16.7823 10.6959C16.6035 10.5057 16.5 10.257 16.5 10.0005C16.5 9.86422 16.5279 9.72916 16.5823 9.60681C16.6368 9.48285 16.7014 9.37829 16.8182 9.26456C16.907 9.18719 16.998 9.12493 17.1224 9.07013C17.4913 8.92396 17.9301 9.0113 18.2043 9.28541C18.2865 9.36639 18.3557 9.46411 18.3942 9.54651L18.4169 9.60494C18.4721 9.72916 18.5 9.86422 18.5 10.0005C18.5 10.2622 18.3978 10.5043 18.2081 10.7058C18.0442 10.8708 17.8289 10.9723 17.5992 10.9951L17.5 11L17.4025 10.9951Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
