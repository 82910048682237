import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const DollarRoundIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                d="M22.1389 14.3958H18.0906C16.6042 14.3949 15.3993 13.1909 15.3984 11.7045C15.3984 10.218 16.6042 9.01413 18.0906 9.01321H22.1389"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M18.5486 11.6429H18.2369" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.24766 3H16.8911C19.7892 3 22.1388 5.34951 22.1388 8.24766V15.4247C22.1388 18.3229 19.7892 20.6724 16.8911 20.6724H8.24766C5.34951 20.6724 3 18.3229 3 15.4247V8.24766C3 5.34951 5.34951 3 8.24766 3Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M7.53561 7.53817H12.9346" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
