import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const ChartIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path d="M7.87142 10.2017V17.0619" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M12.5381 6.91913V17.0618" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M17.1286 13.8268V17.0619" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.1857 2H7.81429C4.54762 2 2.5 4.31208 2.5 7.58516V16.4148C2.5 19.6879 4.5381 22 7.81429 22H17.1857C20.4619 22 22.5 19.6879 22.5 16.4148V7.58516C22.5 4.31208 20.4619 2 17.1857 2Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
