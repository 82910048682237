import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const LeftCircleIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 2.7502C7.392 2.7502 3.25 6.8912 3.25 12.0002C3.25 17.1082 7.392 21.2502 12.5 21.2502C17.608 21.2502 21.75 17.1082 21.75 12.0002C21.75 6.8912 17.608 2.7502 12.5 2.7502Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M13.9423 8.52899L10.4563 12L13.9423 15.471"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
