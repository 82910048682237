import axios from "axios";

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.withCredentials = true;
const platformAPI = process.env.REACT_APP_PLATFORM_API_URL;
axios.interceptors.request.use((config) => {
    let authHeader: any = {
        // "Cache-Control": "no-cache",
    };
    // const token = localStorage.getItem("OMMSAuthToken");
    // if (token) {
    //     authHeader.Authorization = `Bearer ${token}`;
    // }
    config.withCredentials = true;
    config.headers = {
        ...config.headers,
        ...authHeader,
        "content-type": "application/json; charset=utf-8",
    };
    config.baseURL = process.env.REACT_APP_API_URL;
    return config;
});

async function getProfile() {
    return axios.get(platformAPI + 'api/v1/legacy-ui/user-info').then((res) => res.data)
}

function getCardList () {
    return axios.get("/api/v1/cards").then((res) => {
        return res.data
    });
}

function getNextCardList (url: string) {
    return axios.get(url).then((res) => res.data)
}

function getPayPointList () {
    return axios.get('/api/v1/cards/lookupPayPoints').then((res) => res.data);
}

function getEmployees() {
    return axios.get('/api/v1/cards/employees').then(res => res.data);
}

function postCardAction(cardId: string, actionId: string) {
    // /api/v1/cards/action
    return axios.post(`/api/v1/cards/action`, null, {
        params: { cardId, actionId }
    })
    .then(res => res.data);

}

export default {
    getCardList,
    getNextCardList,
    getPayPointList,
    getEmployees,
    getProfile,
    postCardAction
}