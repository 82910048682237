import React from "react";
import { NavigationRoute } from "../../types";

import { AppIcon } from "./SVGFiles";
import { Button } from "antd";
import { AppContext, setActivePage } from "../../context";
import PerfectScrollbar from "react-perfect-scrollbar";

interface Props {
    showFullMenu: boolean;
    onCollapseBtnClick: () => void;
}

export const AppNavigator: React.FC<Props> = ({ showFullMenu, onCollapseBtnClick }) => {
    const { state, dispatch } = React.useContext(AppContext);

    React.useEffect(() => {
        if (showFullMenu) {
            document.body.classList.add("menu-show-full");
        } else {
            document.body.classList.remove("menu-show-full");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showFullMenu]);

    return (
        <div className="ss-dashboard-wrap-navigator">
            <div className="ss-dashboard-wrap-navigator-wrapper">
                <PerfectScrollbar options={{ suppressScrollX: true }}>
                    {state.navLinks.length
                        ? state.navLinks
                              .filter((nav) => nav.enabled)
                              .map((navProps, i) => {
                                  const isActive = navProps.id === state.activePage;
                                  return (
                                      <AppNavLink
                                          {...navProps}
                                          isActive={isActive}
                                          onClick={() =>
                                              !navProps.external && dispatch(setActivePage(navProps.id === state.activePage ? "" : navProps.id))
                                          }
                                          key={i}
                                      />
                                  );
                              })
                        : null}
                    <AppNavLink link={process.env.REACT_APP_LOGOUT_URL as string} label="Logout" icon="logout" id="logout" />
                </PerfectScrollbar>
            </div>
            {/* <AppNavLink
                link="#"
                label="Collapse"
                icon={showFullMenu ? "left-circle" : "right-circle"}
                id="collapse"
                className="ss-dashboard-wrap-collapse-btn"
                onClick={() => onCollapseBtnClick()}
            /> */}
            <Button
                onClick={() => onCollapseBtnClick()}
                type="link"
                className="ss-dashboard-wrap-navigator-close"
                icon={<AppIcon height="36" width="36" asset="close" stroke="#344356" />}
            />
        </div>
    );
};

interface NavLink extends NavigationRoute {
    className?: string;
    isActive?: boolean;
    onClick?: (id: string) => void;
}

const AppNavLink: React.FC<NavLink> = ({ label, icon, id, link, isActive = false, className, onClick, subLinks, external }) => {
    const [subLinkY, setSubLinkY] = React.useState<number | undefined>();
    React.useEffect(() => {
        if (subLinkRef.current) {
            setSubLinkY(subLinkRef.current.clientHeight);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (subLinkRef.current && linkRef.current) {
            if (isActive) {
                const height = subLinkRef.current.clientHeight + linkRef.current.clientHeight + 6;
                setSubLinkY(height);
            } else {
                setSubLinkY(undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive]);

    const linkRef = React.createRef<HTMLDivElement | null>();
    const subLinkRef = React.createRef<HTMLDivElement | null>();
    let linkAttr: any = {
        role: "button",
        onClick: () => onClick && onClick(id),
        className: `ss-dashboard-wrap-nav-item${isActive !== undefined ? (isActive ? " active" : "") : ""}`,
        href: subLinks?.length ? undefined : link,
        style: subLinkY ? { height: subLinkY } : undefined,
        target: external ? "_blank" : undefined,
    };

    if (linkAttr.href && window.location.href.includes("localhost")) {
        linkAttr.href = linkAttr.href.replace("https://dashboard.testaffinitylogon.com", "http://localhost:11128");
    }

    const linkContent = (
        <>
            <AppIcon className="link-icon" asset={icon} />
            <span className="ss-dashboard-wrap-nav-item-label">{label}</span>
            <span className="ss-dashboard-wrap-nav-item-edge ss-dashboard-wrap-nav-item-top-edge">
                <AppIcon asset={"menu-edge-top"} />
            </span>
            <span className="ss-dashboard-wrap-nav-item-edge ss-dashboard-wrap-nav-item-bottom-edge">
                <AppIcon asset={"menu-edge-bottom"} />
            </span>
        </>
    );

    return (
        <div className={`link-item-wrapper ${className || ""}`}>
            {subLinks?.length ? (
                <div {...linkAttr} ref={linkRef}>
                    <div>
                        {linkContent}{" "}
                        <span className="ss-dashboard-wrap-sub-link-arrow">
                            <AppIcon asset="arrow-down" height="16" width="16" className="sub-link-arrow" />
                        </span>
                    </div>
                    <SubLinks subLinks={subLinks} ref={subLinkRef} />
                </div>
            ) : (
                <a {...linkAttr}>{linkContent}</a>
            )}
        </div>
    );
};

interface ISubLinks {
    subLinks: NavigationRoute[];
}

const SubLinks = React.forwardRef<HTMLDivElement | null, ISubLinks>(({ subLinks }, ref) => {
    return (
        <div className="ss-dashboard-wrap-nav-sub-links" ref={ref}>
            {subLinks.map((navProps, i) => {
                return (
                    <a
                        role={"button"}
                        className={`ss-dashboard-wrap-nav-item sub-link-item ${navProps.active ? " active" : ""}`}
                        key={i}
                        href={navProps.link}
                        onClick={(e) => e.stopPropagation()}
                        rel="noreferrer"
                        target={navProps.external ? "_blank" : undefined}>
                        <AppIcon asset={navProps.icon} />
                        <span className="ss-dashboard-wrap-nav-item-label">{navProps.label}</span>
                    </a>
                );
            })}
        </div>
    );
});
