import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const UserBossIco: React.FC<IAppIco> = ({ asset, stroke, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <circle
                cx="12.444141"
                cy="7.1076307"
                r="4.7780299"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                {..._fillCommonProps}
                d="m 4.8651612,18.531001 c -0.00129,-0.3359 0.07383,-0.6677 0.21968,-0.9703 0.45766,-0.9153 1.7482804,-1.4004 2.8192204,-1.6201 0.7723593,-0.1648 1.5553893,-0.275 2.3432494,-0.3295 1.45863,-0.1282 2.92573,-0.1282 4.38443,0 0.7878,0.0552 1.5708,0.1653 2.3433,0.3295 1.0709,0.2197 2.3615,0.659 2.8192,1.6201 0.2933,0.6168 0.2933,1.3329 0,1.9497 -0.4577,0.9611 -1.7483,1.4004 -2.8192,1.611 -0.7715,0.1716 -1.5548,0.2848 -2.3433,0.3386 -1.1872,0.1007 -2.38,0.119 -3.5698,0.055 -0.2746,0 -0.54,0 -0.81463,-0.055 -0.7855401,-0.0531 -1.5658501,-0.1663 -2.3340994,-0.3386 -1.08009,-0.2106 -2.3615504,-0.6499 -2.8283704,-1.611 -0.1451,-0.3061 -0.22015,-0.6407 -0.21968,-0.9794 z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                className="fill-path"
                d="m 9.5964264,16.67887 c 0.248357,-0.234698 0.6370046,-0.256035 0.9108726,-0.06401 l 0.07846,0.06401 2.244509,1.525674 2.086412,-1.522771 c 0.24836,-0.234698 0.636965,-0.256035 0.910825,-0.06401 l 0.07845,0.06401 c 0.24836,0.234699 0.270969,0.60196 0.06774,0.860761 l -0.06774,0.07415 -2.581025,1.990002 c -0.248361,0.234699 -0.637,0.256038 -0.910868,0.06401 l -0.07846,-0.06401 -2.7391776,-1.992905 c -0.273195,-0.258171 -0.273195,-0.676748 0,-0.934912 z"
                fill={fill || "currentColor"}
                strokeWidth="0.818096"
            />
        </svg>
    );
};
