import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const DownSquareIco: React.FC<IAppIco> = ({ asset, height, stroke, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M7.666 21.25H16.335C19.355 21.25 21.25 19.111 21.25 16.084V7.916C21.25 4.889 19.365 2.75 16.335 2.75H7.666C4.636 2.75 2.75 4.889 2.75 7.916V16.084C2.75 19.111 4.636 21.25 7.666 21.25Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M12 16.086V7.914" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                d="M15.748 12.3223L12 16.0863L8.25195 12.3223"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
