import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const UnlockIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                d="M16.9242 5.56204C16.3072 3.78004 14.6142 2.50004 12.6222 2.50004C10.1092 2.49004 8.06319 4.51804 8.05219 7.03104V7.05104V9.19804"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.433 21.0004H8.792C6.698 21.0004 5 19.3024 5 17.2074V12.9194C5 10.8244 6.698 9.12643 8.792 9.12643H16.433C18.527 9.12643 20.225 10.8244 20.225 12.9194V17.2074C20.225 19.3024 18.527 21.0004 16.433 21.0004Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M12.6127 13.9526V16.1746" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
