import React from "react";
import { IAppIco } from "../types";

export const CreditCardIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.81204 2.5H16.188C19.3978 2.5 22 5.16116 22 8.44374V9.24063V15.5563C22 18.8388 19.3978 21.5 16.188 21.5H7.81204C4.60219 21.5 2 18.8388 2 15.5563V9.24063V8.44374C2 5.16116 4.60219 2.5 7.81204 2.5ZM3.45358 9.94209V15.5563C3.45358 18.0179 5.40498 20.0135 7.81204 20.0135H16.188C18.595 20.0135 20.5464 18.0179 20.5464 15.5563V9.94209C20.4102 9.95844 20.2678 9.97029 20.1208 9.97711L19.8258 9.98389H4.17424C3.92165 9.98389 3.67911 9.96917 3.45358 9.94209ZM20.5464 8.53918C20.3209 8.5121 20.0784 8.49738 19.8258 8.49738H4.17424L3.87921 8.50416C3.73215 8.51098 3.58976 8.52283 3.45358 8.53918V8.44374C3.45358 5.98214 5.40498 3.98651 7.81204 3.98651H16.188C18.595 3.98651 20.5464 5.98214 20.5464 8.44374V8.53918ZM12.294 14.4402C12.6954 14.4402 13.0208 14.773 13.0208 15.1834C13.0208 15.5597 12.7473 15.8707 12.3926 15.9199L12.294 15.9267H11.9919C11.5905 15.9267 11.2651 15.5939 11.2651 15.1834C11.2651 14.8072 11.5386 14.4962 11.8933 14.447L11.9919 14.4402H12.294ZM15.044 14.4402C15.4454 14.4402 15.7708 14.773 15.7708 15.1834C15.7708 15.5597 15.4973 15.8707 15.1426 15.9199L15.044 15.9267H14.7419C14.3405 15.9267 14.0151 15.5939 14.0151 15.1834C14.0151 14.8072 14.2886 14.4962 14.6433 14.447L14.7419 14.4402H15.044ZM18.6277 15.1834C18.6277 14.773 18.3023 14.4402 17.9009 14.4402H17.5989L17.5002 14.447C17.1455 14.4962 16.8721 14.8072 16.8721 15.1834C16.8721 15.5939 17.1975 15.9267 17.5989 15.9267H17.9009L17.9995 15.9199C18.3543 15.8707 18.6277 15.5597 18.6277 15.1834Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
