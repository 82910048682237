import React from "react";
import { IAppIco } from "../types";

export const TwitterIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg
            className={_classList}
            width={width || "24"}
            height={height || "24"}
            viewBox="64 64 896 896"
            focusable="false"
            fill={fill || "currentColor"}>
            <path
                d="m 852.09737,307.51385 c -25.01745,10.47426 -52.24232,18.01255 -80.28475,20.94852 a 139.06758,134.97512 0 0 0 61.31727,-74.58943 275.22464,267.12536 0 0 1 -88.46039,32.69239 139.06758,134.97512 0 0 0 -101.86843,-42.76989 c -77.25977,0 -139.39461,60.78244 -139.39461,135.37186 0,10.47426 1.3081,20.94852 3.43377,31.02603 C 391.15497,404.3214 287.97843,350.68049 219.38484,268.55278 a 138.42988,134.35618 0 0 0 -18.96748,68.32073 c 0,46.97547 24.60866,88.39641 62.13484,112.75699 a 140.62095,136.48277 0 0 1 -63.03416,-17.21904 v 1.66635 c 0,65.78153 47.90923,120.29529 111.76095,132.83266 a 147.652,143.30691 0 0 1 -36.70861,4.60232 c -9.07495,0 -17.65937,-0.87285 -26.32555,-2.06311 17.65938,53.64091 69.08413,92.60198 130.31965,93.87159 -47.90923,36.42185 -107.9184,57.84647 -173.07823,57.84647 -11.69116,0 -22.483,-0.39675 -33.68362,-1.66636 61.80781,38.48497 135.14328,60.7031 214.11993,60.7031 256.38797,0 396.6819,-206.15247 396.6819,-385.08774 0,-5.87193 0,-11.74386 -0.40878,-17.6158 27.14311,-19.28216 50.93421,-43.16664 69.90169,-69.98709 z"
                strokeWidth="0.805444"
            />
        </svg>
    );
};
