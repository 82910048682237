import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const LocationIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg
            className={_classList}
            width={width || "25"}
            height={height || "24"}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                {..._fillCommonProps}
                d="M12.5288 2.00005C7.91977 1.98439 4.1551 5.65135 4.00463 10.2414L4 10.5263C4.07661 12.7761 4.84207 14.9207 6.19028 16.6876L6.50692 17.1008C7.7938 18.7371 9.32311 20.1733 11.0403 21.3571L11.4537 21.6354L11.514 21.6828C12.1026 22.1057 12.8973 22.1057 13.4858 21.6828L13.536 21.6415C14.8986 20.7579 16.155 19.7196 17.2805 18.5473C19.5686 16.1252 20.9477 13.4112 20.9998 10.655L20.9999 10.5526C21.0155 5.93192 17.3598 2.15567 12.784 2.00469L12.5288 2.00005ZM12.5237 3.51747C16.3022 3.53038 19.37 6.54942 19.4839 10.3091L19.4871 10.6406C19.4434 12.9514 18.2334 15.3324 16.1866 17.4991C15.1429 18.5862 13.9727 19.5526 12.7036 20.3745L12.619 20.438C12.5506 20.4974 12.4492 20.4974 12.3809 20.438L12.2987 20.376C10.408 19.1416 8.74857 17.583 7.39636 15.7717C6.30718 14.3442 5.66175 12.6374 5.53184 10.8569L5.51275 10.4999C5.52559 6.71221 8.53532 3.63498 12.2835 3.52071L12.5237 3.51747ZM12.4999 7.40129C10.7115 7.40129 9.26176 8.85554 9.26176 10.6495C9.26176 12.4434 10.7115 13.8976 12.4999 13.8976C14.2883 13.8976 15.7381 12.4434 15.7381 10.6495C15.7381 8.85554 14.2883 7.40129 12.4999 7.40129ZM12.4999 8.91871C13.4528 8.91871 14.2253 9.69359 14.2253 10.6495C14.2253 11.6053 13.4528 12.3802 12.4999 12.3802C11.547 12.3802 10.7745 11.6053 10.7745 10.6495C10.7745 9.69359 11.547 8.91871 12.4999 8.91871Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
