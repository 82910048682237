import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const PaperUploadIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2363 2.7619H8.58431C6.52531 2.7619 4.75031 4.4309 4.75031 6.4909V17.2279C4.75031 19.4039 6.40831 21.1149 8.58431 21.1149H16.5723C18.6323 21.1149 20.3023 19.2879 20.3023 17.2279V8.0379L15.2363 2.7619Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M14.9736 2.75021V5.65921C14.9736 7.07921 16.1226 8.23121 17.5426 8.23421C18.8586 8.23721 20.2056 8.23821 20.2966 8.23221"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M12.1406 9.44061V16.0136" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                d="M9.3022 12.2912L12.1402 9.44022L14.9792 12.2912"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
