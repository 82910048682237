import React from "react";
import { AppHeader, AppNavigator } from "./DashboardWrapper";
import {
    AppContext,
    setUserNavLinks,
    setShowFullMenu,
    setUserInfo,
    setFormLinks,
    setActivePage,
} from "../context";
import { fetchInitialData } from "../remote";

import "../remote";
import "./App.less";

const resource = fetchInitialData();

function App() {
    const { state, dispatch } = React.useContext(AppContext);
    const dataProps = resource.payslip.read();

    React.useEffect(() => {        
        if (dataProps.navLinks && dataProps.user) {
            dispatch(setUserInfo(dataProps.user));
            dispatch(setUserNavLinks(dataProps.navLinks));
            dispatch(setFormLinks(dataProps.formLinks));
            dispatch(setActivePage(dataProps.activePage));

            const body = document.body || document.getElementsByTagName("body");
            body.classList.add("ss-dashboard-wrapper");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {(dataProps.user && dataProps.user.EmployeeNo) &&
                <AppHeader
                    title=""
                    onMenuClick={() =>
                        dispatch(setShowFullMenu(!state.showFullMenu))
                    }
                />
            }
            {
                (dataProps.navLinks && dataProps.navLinks.length > 0) &&
                <AppNavigator
                    showFullMenu={state.showFullMenu}
                    onCollapseBtnClick={() =>
                        dispatch(setShowFullMenu(!state.showFullMenu))
                    }
                />
            }
          
            <button id="dbwEventHandler"></button>
        </>
    );
}

export default App;
