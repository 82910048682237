import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const CogIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M11.8853 2.10169L5.24174 4.37005C4.49797 4.62306 4 5.30545 4 6.07198V12.7097C4 14.7315 4.7529 16.6789 6.10912 18.196C6.74795 18.9121 7.56192 19.5077 8.54931 20.0259L12.1466 21.9118C12.3711 22.0295 12.6414 22.0294 12.8658 21.9116L16.4568 20.0269C17.4415 19.5095 18.2555 18.9127 18.8944 18.1964C20.2486 16.6804 21 14.7342 21 12.7136V6.07198C21 5.30545 20.502 4.62306 19.7574 4.36977L13.1156 2.10202C12.718 1.96605 12.2837 1.96605 11.8853 2.10169ZM12.6131 3.48951L19.2563 5.75777C19.3945 5.80477 19.4861 5.9303 19.4861 6.07198V12.7136C19.4861 14.3792 18.8668 15.9832 17.7508 17.2327L17.5525 17.4427C17.0722 17.9246 16.4672 18.3492 15.7369 18.7329L12.505 20.4283L9.26848 18.7316C8.4313 18.2922 7.76044 17.8013 7.25256 17.232C6.13446 15.9813 5.51389 14.3761 5.51389 12.7097V6.07198C5.51389 5.9303 5.6055 5.80477 5.74283 5.75805L12.3866 3.48962C12.4599 3.46466 12.5404 3.46466 12.6131 3.48951ZM16.2553 9.2399C15.9597 8.95269 15.4804 8.95269 15.1848 9.2399L11.7854 12.5422L10.4118 11.2062L10.3269 11.1349C10.0307 10.9212 9.61013 10.9448 9.34132 11.2059C9.04564 11.493 9.04551 11.9587 9.34104 12.246L11.2506 14.1023L11.3355 14.1735C11.6318 14.3872 12.0524 14.3636 12.3212 14.1024L16.2553 10.28L16.3286 10.1975C16.5485 9.90959 16.524 9.501 16.2553 9.2399Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
