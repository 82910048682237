import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const CycleIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path d="M17.3396 20.1642V6.54645" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                d="M21.4172 16.0681L17.3394 20.1648L13.2617 16.0681"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M7.41112 3.83289V17.4507" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                d="M3.33344 7.929L7.41121 3.83234L11.489 7.929"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
