import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const DeleteIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg
            className={_classList}
            width={width || "25"}
            height={height || "24"}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.8249 9.4682C19.8249 9.4682 19.2819 16.2032 18.9669 19.0402C18.8169 20.3952 17.9799 21.1892 16.6089 21.2142C13.9999 21.2612 11.3879 21.2642 8.77991 21.2092C7.46091 21.1822 6.63791 20.3782 6.49091 19.0472C6.17391 16.1852 5.63391 9.4682 5.63391 9.4682"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M21.2082 6.2397H4.25021" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                d="M17.9406 6.2397C17.1556 6.2397 16.4796 5.6847 16.3256 4.9157L16.0826 3.6997C15.9326 3.1387 15.4246 2.7507 14.8456 2.7507H10.6126C10.0336 2.7507 9.52561 3.1387 9.37561 3.6997L9.13261 4.9157C8.97861 5.6847 8.30261 6.2397 7.51761 6.2397"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
