import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const WorkIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path
                d="M3.30411 15.477C3.30411 15.477 3.44611 17.215 3.47911 17.763C3.52311 18.498 3.80711 19.319 4.28111 19.889C4.95011 20.697 5.73811 20.982 6.79011 20.984C8.02711 20.986 17.0221 20.986 18.2591 20.984C19.3111 20.982 20.0991 20.697 20.7681 19.889C21.2421 19.319 21.5261 18.498 21.5711 17.763C21.6031 17.215 21.7451 15.477 21.7451 15.477"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M8.996 5.32949V4.95849C8.996 3.73849 9.984 2.75049 11.204 2.75049H13.786C15.005 2.75049 15.994 3.73849 15.994 4.95849L15.995 5.32949"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M12.495 16.6782V15.3842" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.24991 8.38909V11.8561C5.16791 13.1211 7.46591 14.0071 9.98791 14.3581C10.2899 13.2571 11.2829 12.4501 12.4899 12.4501C13.6779 12.4501 14.6909 13.2571 14.9729 14.3681C17.5049 14.0171 19.8119 13.1311 21.7399 11.8561V8.38909C21.7399 6.69509 20.3769 5.33109 18.6829 5.33109H6.31691C4.62291 5.33109 3.24991 6.69509 3.24991 8.38909Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
