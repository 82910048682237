import React from "react";
import { IAppIco } from "../types";

export const MenuBottomEdgeIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className="menu-edge" width={width || "10"} height={height || "10"} viewBox="10 0 10 10" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C4.47715 0 0 4.47715 0 10V0H10ZM20 10C20 4.47715 15.5228 0 10 0H20V10ZM10 20C4.47715 20 0 15.5228 0 10V20H10ZM10 20C15.5228 20 20 15.5228 20 10V20H10Z"
                fill={fill || "#f7f9ff"}
            />
        </svg>
    );
};
