import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const RightCircleIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 21.2498C17.608 21.2498 21.75 17.1088 21.75 11.9998C21.75 6.89179 17.608 2.74979 12.5 2.74979C7.392 2.74979 3.25 6.89179 3.25 11.9998C3.25 17.1088 7.392 21.2498 12.5 21.2498Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path
                d="M11.0577 15.471L14.5437 12L11.0577 8.52898"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
        </svg>
    );
};
