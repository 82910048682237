import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const TwoUserIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M10.0914 2.4998C7.2435 2.4998 4.9574 4.78627 4.9574 7.6338C4.9574 10.4822 7.24332 12.7688 10.0914 12.7688C12.9396 12.7688 15.2264 10.482 15.2264 7.6338C15.2264 4.78641 12.9394 2.4998 10.0914 2.4998ZM10.0914 3.9998C12.1111 3.9998 13.7264 5.61489 13.7264 7.6338C13.7264 9.65359 12.1112 11.2688 10.0914 11.2688C8.07186 11.2688 6.4574 9.65383 6.4574 7.6338C6.4574 5.61464 8.07198 3.9998 10.0914 3.9998ZM20.5671 7.6164C20.5671 5.38188 18.755 3.5694 16.5201 3.5694C16.1059 3.5694 15.7701 3.90519 15.7701 4.3194C15.7701 4.73361 16.1059 5.0694 16.5201 5.0694C17.9265 5.0694 19.0671 6.21023 19.0671 7.6164C19.0671 9.02319 17.9269 10.1634 16.5201 10.1634C16.1059 10.1634 15.7701 10.4992 15.7701 10.9134C15.7701 11.3276 16.1059 11.6634 16.5201 11.6634C18.7553 11.6634 20.5671 9.85161 20.5671 7.6164ZM19.7995 13.9938C19.2448 13.8708 18.6677 13.7876 18.0871 13.7481C17.6738 13.72 17.316 14.0322 17.2879 14.4455C17.2598 14.8588 17.572 15.2166 17.9853 15.2447C18.4909 15.2791 18.9937 15.3516 19.4911 15.4617C20.2677 15.6163 20.7821 15.8732 20.9212 16.1651C21.026 16.3856 21.026 16.6442 20.9208 16.8654C20.7827 17.1562 20.2709 17.4126 19.5021 17.5708C19.0964 17.6542 18.8351 18.0508 18.9186 18.4565C19.002 18.8622 19.3986 19.1235 19.8043 19.04C20.9991 18.7943 21.8732 18.3563 22.2756 17.5093C22.5744 16.8806 22.5744 16.1492 22.2756 15.5204C21.8709 14.6713 20.9876 14.2301 19.7995 13.9938ZM2.5004 18.0183C2.5004 15.3937 5.25799 14.4563 10.0914 14.4563L10.4112 14.4577C15.0556 14.4981 17.6834 15.4361 17.6834 17.9983C17.6834 20.5062 15.1649 21.4736 10.7235 21.5547L10.0914 21.5603C5.24706 21.5603 2.5004 20.6394 2.5004 18.0183ZM16.1834 17.9983C16.1834 16.6506 14.1128 15.9563 10.0914 15.9563C6.07405 15.9563 4.0004 16.6612 4.0004 18.0183C4.0004 19.3667 6.06907 20.0603 10.0914 20.0603C14.109 20.0603 16.1834 19.3553 16.1834 17.9983Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
