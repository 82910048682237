import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const ActivityIco: React.FC<IAppIco> = ({ asset, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M17.3212 4.58138C17.3212 3.15572 18.4805 2 19.9106 2C21.3407 2 22.5 3.15572 22.5 4.58138C22.5 6.00703 21.3407 7.16275 19.9106 7.16275C18.4805 7.16275 17.3212 6.00703 17.3212 4.58138ZM21.0465 4.58138C21.0465 3.95599 20.5379 3.44902 19.9106 3.44902C19.2833 3.44902 18.7747 3.95599 18.7747 4.58138C18.7747 5.20676 19.2833 5.71373 19.9106 5.71373C20.5379 5.71373 21.0465 5.20676 21.0465 4.58138ZM16.0277 9.54022C16.2736 9.22392 16.7301 9.16618 17.0473 9.41126C17.3358 9.63406 17.4099 10.0304 17.2363 10.3383L17.1767 10.4277L14.3384 14.0796C14.1139 14.3685 13.7139 14.4415 13.4047 14.2658L13.315 14.2056L10.5835 12.0668L8.13115 15.2448C7.90864 15.5331 7.51131 15.6083 7.2019 15.4363L7.11198 15.3772C6.82279 15.1554 6.74735 14.7593 6.91992 14.4508L6.97916 14.3612L9.87954 10.6032C10.1036 10.3129 10.5046 10.239 10.8145 10.415L10.9045 10.4753L13.6368 12.6155L16.0277 9.54022ZM15.7236 3.53799C15.7236 3.13786 15.3982 2.81348 14.9968 2.81348H7.95429L7.72732 2.81726C4.57521 2.92283 2.5 5.23062 2.5 8.50732V16.315L2.50348 16.5481C2.60063 19.7878 4.7269 22 7.95429 22H16.2918L16.5188 21.9962C19.6721 21.891 21.7461 19.5901 21.7461 16.315V9.51534L21.7394 9.41702C21.6913 9.06339 21.3872 8.79083 21.0193 8.79083C20.6179 8.79083 20.2926 9.1152 20.2926 9.51534V16.315L20.2886 16.5399C20.2007 18.9868 18.6725 20.551 16.2918 20.551H7.95429L7.7384 20.5467C5.39102 20.4534 3.95352 18.8291 3.95352 16.315V8.50732L3.95752 8.28224C4.04548 5.83278 5.57567 4.2625 7.95429 4.2625H14.9968L15.0954 4.25589C15.4502 4.20791 15.7236 3.90478 15.7236 3.53799Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
