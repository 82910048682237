import React, { useEffect, useState } from 'react';
// import {getCardList, getNextCardList, getPayPointList} from './api';
import {Row, Col, Input, Button, Select, AutoComplete} from 'antd';
import {mockPayPointData} from "../../mockData/mockPayPoint";
import Remote from "../../api";
// import './Page1.less'

const { Option } = Select;

interface Props {
    selectedFilter: {
        search: string,
        payPoint: string,
        periodEndDate: string | null,
        status: string,
        employee: string,
        subject: string,
        messageType: string,
    }
    onSearchClick: () => void;
}

export function CardFilter(props: Props) {
    const [payPointList, setPayPointList] = useState([] as any[]);
    const [selectedPayPoint, setSelectedPayPoint] = useState("");
    const [periodEndDateList, setPeriodEndDateList] = useState([] as any);
    const [selectedPeriodEndDate, setSelectedPeriodEndDate] = useState("" as any);
    const [employeeList, setEmployeeList] = useState([] as any[]);
    const [originEmployeeList, setOriginEmployeeList] = useState([] as any[]);
    const [showFilter, setShowFilter] = useState(true);

    useEffect(() => {
        Remote.getEmployees()
            .then((data: any) => {
                const mappedEmployeeList = data.map((d: any) => {
                    return {
                        label: `${d.displayName} (${d.id}) `,
                        value: d.id
                    }
                })
                setEmployeeList(mappedEmployeeList)
                setOriginEmployeeList(mappedEmployeeList.slice());
            })

        Remote.getPayPointList().then((data: any) => {
            setPayPointList(data);
            setSelectedPayPoint(data[0].id)
            props.selectedFilter.payPoint = data[0].id
            setPeriodEndDateList(data[0].payPeriods.map((payPoint:any) => payPoint.endDate))
            props.onSearchClick()
        })
    }, [])

    function onChangePayPoint(selection: any) {
        setSelectedPayPoint(selection)
        props.selectedFilter.payPoint = selection
        const selectedPayPointDateList = payPointList.find((payPoint: any) => {
            return payPoint.id === selection
        })

        setPeriodEndDateList(selectedPayPointDateList.payPeriods.map((payPoint:any) => payPoint.endDate))
        setSelectedPeriodEndDate(null)
        props.selectedFilter.periodEndDate = null
        props.onSearchClick()
    }

    function onChangePeriodEndDate(selectedDate: string) {
        setSelectedPeriodEndDate(selectedDate);
        props.selectedFilter.periodEndDate = selectedDate
        props.onSearchClick()
    }

    function onChangeSearchText(searchText: string) {
        props.selectedFilter.search = searchText;
        props.onSearchClick()
    }

    function onChangeSelectedStatus(selectedStatus: string) {
        props.selectedFilter.status = selectedStatus;
        props.onSearchClick()
    }

    function onChangeSelectedEmployee(selectedEmployee: string) {
        props.selectedFilter.employee = selectedEmployee;
        props.onSearchClick()
    }

    const onSearchEmployee = (text: string) => {
        setEmployeeList(originEmployeeList.filter((employee: any) => {
            return (employee.label.toLowerCase() + employee.value).indexOf(text) > -1
        }))
        props.onSearchClick()

    }

    function onChangeSelectedSubject(selectedSubject: string) {
        props.selectedFilter.subject = selectedSubject;
        props.onSearchClick()

    }

    function onChangeSelectedMessageType(selectedMessageType: string) {
        props.selectedFilter.messageType = selectedMessageType;
        props.onSearchClick()

    }

    function toggleFilter() {
        setShowFilter(!showFilter)
    }

    return (
        <div>
            <Row justify='space-between' className="flex justify-between mt-2">
                <Col span={12}>
                    <Button type="primary" size={"middle"} onClick={() => toggleFilter()}>Filter</Button>
                    <Input.Search className="ml-4" allowClear style={{ width: '200px' }}
                                  onSearch={onChangeSearchText} placeholder="input here" />
                </Col>
                <Col>
                    <Select style={{ width: 220 }}
                            value={selectedPayPoint}
                            size={"middle"}
                            optionLabelProp="label"
                            onChange={onChangePayPoint}>
                        {payPointList.map(payPoint => (
                            <Option key={payPoint.id}
                                    value={payPoint.id}
                                    label={`${payPoint.description} ( ${payPoint.id} )`}>{`${payPoint.description} ( ${payPoint.id} )`}</Option>
                        ))}
                    </Select>
                    <Select className={"ml-4"}
                            style={{ width: 150 }}
                            value={ selectedPeriodEndDate }
                            onChange={ onChangePeriodEndDate }
                            allowClear>
                        {periodEndDateList.map((payPointDate: string) => (
                            <Option
                                key={payPointDate}
                                value={payPointDate}>{payPointDate}</Option>
                        ))}
                    </Select>
                    {/* <Button type="primary" className="ml-4" onClick={() => props.onSearchClick()}>Refresh</Button> */}
                </Col>

            </Row>

            {
                showFilter && 
                <div className="grid-filter">
                    <div >
                        Status
                        <Select style={{ width: 100 }}
                                className="ml-2"
                                size={"middle"}
                                onChange={onChangeSelectedStatus}
                                allowClear>
                            <Option value="pending">Pending</Option>
                            <Option value="approved">Approved</Option>
                            <Option value="dismissed">Dismissed</Option>
                            <Option value="deleted">Deleted</Option>
                            <Option value="expired">Expired</Option>
                        </Select>
                    </div>
                    <div className="ml-4">
                        <span>Employee</span>
                        <Select
                            showSearch
                            allowClear
                            className="ml-2"
                            style={{width: 200}}
                            placeholder="Select a person"
                            onChange={onChangeSelectedEmployee}
                            onSearch={onSearchEmployee}>
                            { employeeList.map((employee => <Option value={employee.value} key={employee.value}>{employee.label}</Option>)) }
                        </Select>
                    </div>

                    <div className="ml-4">
                        Subject
                        <Select style={{ width: 150 }}
                                className="ml-2"
                                size={"middle"}
                                onChange={onChangeSelectedSubject}
                                allowClear>
                            <Option value="leave">Leave</Option>
                            <Option value="timesheet">Timesheets</Option>
                            <Option value="forms">Forms</Option>
                            <Option value="configuration">Configuration</Option>
                            <Option value="payroll">Payroll</Option>
                            <Option value="employee">Employees</Option>
                            <Option value="payslips">Payslips</Option>
                            <Option value="other">Other</Option>
                        </Select>
                    </div>
                    <div className="ml-4">
                        Message Type
                        <Select style={{ width: 150 }}
                                className="ml-2"
                                size={"middle"}
                                onChange={onChangeSelectedMessageType}
                                allowClear>
                            <Option value="Exception">Exception</Option>
                            <Option value="Warning">Warning</Option>
                            <Option value="Approval">Approval</Option>
                            <Option value="Information">Information</Option>
                        </Select>
                    </div>
                    {/* <Button className="ml-4" onClick={() => props.onSearchClick()}>Search</Button> */}
                </div>
            }
            
        </div>




    )
}