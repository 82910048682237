import React from "react";
import { IAppIco } from "../types";
import { _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const PlusIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-${asset}`;

    return (
        <svg className={_classList} width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none">
            <path d="M12.5 5V18" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M19 11.5H6" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
