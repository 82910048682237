import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const HelpIco: React.FC<IAppIco> = ({ asset, stroke, fill, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "30"} height={height || "30"} viewBox="0 0 30 30" fill="none">
            <path
                d="M15 3.43774C21.3863 3.43774 26.5625 8.61524 26.5625 15.0002C26.5625 21.3852 21.3863 26.5627 15 26.5627C8.615 26.5627 3.4375 21.3852 3.4375 15.0002C3.4375 8.61524 8.615 3.43774 15 3.43774Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
                {..._fillCommonProps}
            />
            <path
                className="fill-path"
                d="M10.625 12.2011C10.625 11.6782 10.8029 11.1498 11.1587 10.6159C11.5146 10.0764 12.0337 9.63061 12.7162 9.27834C13.3987 8.92607 14.195 8.74994 15.105 8.74994C15.9508 8.74994 16.6975 8.89855 17.345 9.19578C17.9925 9.4875 18.4913 9.88655 18.8413 10.3929C19.1971 10.8993 19.375 11.4497 19.375 12.0442C19.375 12.512 19.2729 12.9221 19.0688 13.2744C18.8704 13.6266 18.6312 13.9321 18.3512 14.1908C18.0771 14.444 17.5812 14.8733 16.8637 15.4788C16.6654 15.6494 16.505 15.8008 16.3825 15.9329C16.2658 16.0595 16.1783 16.1778 16.12 16.2879C16.0617 16.3925 16.015 16.4998 15.98 16.6099C15.9508 16.7145 15.9042 16.9016 15.84 17.1713C15.7292 17.7438 15.3821 18.03 14.7987 18.03C14.4954 18.03 14.2387 17.9364 14.0287 17.7493C13.8246 17.5621 13.7225 17.2842 13.7225 16.9154C13.7225 16.453 13.7983 16.054 13.95 15.7182C14.1017 15.377 14.3029 15.0797 14.5537 14.8266C14.8046 14.5679 15.1429 14.2624 15.5687 13.9101C15.9421 13.6019 16.2104 13.3707 16.3737 13.2166C16.5429 13.057 16.6829 12.8808 16.7937 12.6882C16.9104 12.4955 16.9687 12.2864 16.9687 12.0607C16.9687 11.6204 16.7937 11.2488 16.4437 10.9461C16.0996 10.6434 15.6533 10.492 15.105 10.492C14.4633 10.492 13.9908 10.6461 13.6875 10.9544C13.3842 11.2571 13.1275 11.7057 12.9175 12.3001C12.7192 12.9221 12.3429 13.2331 11.7888 13.2331C11.4621 13.2331 11.185 13.1258 10.9575 12.9111C10.7358 12.6909 10.625 12.4543 10.625 12.2011ZM14.895 21.2499C14.5392 21.2499 14.2271 21.1426 13.9587 20.9279C13.6962 20.7078 13.565 20.4023 13.565 20.0115C13.565 19.6647 13.6933 19.373 13.95 19.1363C14.2067 18.8997 14.5217 18.7813 14.895 18.7813C15.2625 18.7813 15.5717 18.8997 15.8225 19.1363C16.0733 19.373 16.1987 19.6647 16.1987 20.0115C16.1987 20.3968 16.0675 20.6995 15.805 20.9197C15.5425 21.1399 15.2392 21.2499 14.895 21.2499Z"
                fill={fill || "currentColor"}
            />
        </svg>
    );
};
