import React from "react";
import { IAppIco } from "../types";
import { _fillCommonProps, _strokeCommonProps } from "../component/DashboardWrapper/SVGFiles";

export const ShieldFailIco: React.FC<IAppIco> = ({ asset, stroke, height, width, className }) => {
    const _classList = `${className || ""} ss-app-config-ico-` + asset;

    return (
        <svg className={_classList} width={width || "25"} height={height || "24"} viewBox="0 0 25 24" fill="none">
            <path
                {..._fillCommonProps}
                d="M19.3651 5.1238C19.8021 5.2768 20.0941 5.6888 20.0941 6.1518V12.9248C20.0941 14.8178 19.4051 16.6248 18.1911 18.0248C17.5791 18.7298 16.8071 19.2788 15.9861 19.7228L12.4281 21.6448L8.86411 19.7218C8.04111 19.2778 7.26811 18.7298 6.65611 18.0238C5.44011 16.6238 4.75011 14.8158 4.75011 12.9208V6.1518C4.75011 5.6888 5.04211 5.2768 5.47911 5.1238L12.0611 2.8108C12.2951 2.7288 12.5501 2.7288 12.7831 2.8108L19.3651 5.1238Z"
                stroke={stroke || "currentColor"}
                {..._strokeCommonProps}
            />
            <path d="M14.3011 13.8464L10.5431 10.0884" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
            <path d="M10.5433 13.8464L14.3013 10.0884" stroke={stroke || "currentColor"} {..._strokeCommonProps} />
        </svg>
    );
};
