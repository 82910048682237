import React, { createContext, useReducer } from "react";
import { NavigationRoute, Employee } from "../types";
import { Actions, ActionType } from "./actions";

interface IState {
    isAuth: boolean;
    fetchingData: boolean;
    showFullMenu: boolean;
    currentPage: any;
    showAllPayslip: boolean;
    latestYear: number;
    navLinks: NavigationRoute[];
    user?: Employee;
    formLinks?: NavigationRoute[];
    activePage: string;
}

const initialState: IState = {
    isAuth: false,
    fetchingData: false,
    showFullMenu: window.innerWidth > 1200, // MENU open in larger screen
    currentPage: "",
    showAllPayslip: false,
    latestYear: 0,
    navLinks: [],
    activePage: "dashboard",
};

interface IAppContext {
    state: IState;
    dispatch: React.Dispatch<Actions>;
}

const AppContext = createContext<IAppContext>({
    state: initialState,
    dispatch: () => null,
});

const { Provider } = AppContext;

const reducer = (state: IState, action: Actions) => {
    switch (action.type) {
        case ActionType.SetFetchingData:
            return {
                ...state,
                fetchingData: action.payload,
            };

        case ActionType.SetShowFullMenu:
            return {
                ...state,
                showFullMenu: action.payload,
            };

        case ActionType.SetCurrentPage:
            return {
                ...state,
                currentPage: action.payload,
            };

        case ActionType.SetUserNavLinks: {
            return {
                ...state,
                navLinks: action.payload,
            };
        }
            

        case ActionType.SetUserInfo:
            return {
                ...state,
                user: action.payload,
            };

        case ActionType.SetUserLinks:
            return {
                ...state,
                formLinks: action.payload,
            };

        case ActionType.SetActivePage:
            return {
                ...state,
                activePage: action.payload,
            };

        default:
            return state;
    }
};

const AppProvider = ({ children }: { children: JSX.Element }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </Provider>
    );
};

export { AppContext, AppProvider };
